//* Packages */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components */
import TabList from "@Core/Tabs/TabList";
import TabPane from "@Core/Tabs/TabPane";
import TaskSection from "@Components/TaskSection";
import NotesSection from "@Components/NotesSection";
import SkeletonCard from "@Core/SkeletonLoader/Card";
import Breadcrumbs, { Crumb } from "@Core/Breadcrumbs";
import TemplateSection from "@Components/TemplateSection";
import AgentCard from "@Components/pages/leads/lead-details/AgentCard";
import ErrorComponent from "@Components/pages/leads/lead-details/Error";
import LeadStatus from "@Components/pages/leads/lead-details/LeadStatus";
import LeadFormSection from "@Components/pages/leads/lead-details/LeadFormSection";
import LeadDetailsCard from "@Components/pages/leads/lead-details/LeadDetailsCard";
import LeadDetailsLoader from "@Components/pages/leads/lead-details/LeadDetailsCard/Loader";
import CrossSale from "@Components/pages/leads/lead-details/CrossSale/index";

//* Redux */
import { getLeadAssignees, getLeadDetails } from "@Actions/lead";

//* Utils */
import { StatusKeyEnum, USER_TYPE } from "@Enums/common";
import { StatusKey } from "@Types/common";
import useChangePageTitle from "@Hooks/useChangePageTitle";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Mock */
// import { byPassRBACAccess } from "@/mock";
// import { setUserData } from "@Slice/AuthSlice";

export default function LeadDetails() {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();

  const { access, role } = useAppSelector((state) => state.user);
  const { leadData, leadDataLoading, leadErrorStatus } = useAppSelector(
    (state) => state.lead,
  );

  useChangePageTitle(leadData?.name && `${leadData?.name} (${leadData?.id})`);

  const CRUMBS = useMemo<Crumb[]>(
    () => [
      { id: 0, label: "Lead", link: "/" },
      { id: 1, label: `${leadData?.name || "-"} [${leadId}]` },
    ],
    [leadData?.id, leadId],
  );

  useEffect(() => {
    if (leadId) {
      dispatch(getLeadDetails(Number(leadId)));
      dispatch(getLeadAssignees({ leadId: Number(leadId) }));

      //* Uncomment this line to bypass RBAC access
      // dispatch(setUserData(byPassRBACAccess));
    }
  }, [leadId]);

  return (
    <>
      {leadErrorStatus && <ErrorComponent status={leadErrorStatus} />}
      <div className="relative flex gap-4">
        <div className="left w-[72%]">
          {leadDataLoading ? (
            <>
              <LeadDetailsLoader />
              <SkeletonCard contentLines={9} gridCol={2} />
              <SkeletonCard contentLines={9} gridCol={2} />
            </>
          ) : (
            <>
              {!leadErrorStatus && (
                <>
                  <div className="flex items-center justify-between">
                    <Breadcrumbs crumbs={CRUMBS} />
                    {role !== USER_TYPE.LENDER && <CrossSale />}
                  </div>
                  <div className="mt-4">
                    <LeadStatus
                      currentStatus={leadData?.leadinfo?.status as StatusKey}
                      disabled={!access?.detail.leadDetails.change}
                    />
                  </div>

                  {role !== USER_TYPE.LENDER &&
                    leadData?.leadinfo?.status === StatusKeyEnum.LOST &&
                    leadData?.leadinfo?.lostReason && (
                      <div className="p-2 text-sm rounded border text-error border-error bg-red-50 flex items-center gap-1 mt-4">
                        <span className="font-medium text-error">
                          Lost Reason:
                        </span>
                        <span>{leadData?.leadinfo?.lostReason}</span>
                      </div>
                    )}

                  <div className="mt-4">
                    <LeadDetailsCard />
                  </div>
                  {role === USER_TYPE.LENDER && (
                    <div className="mt-4">
                      <AgentCard />
                    </div>
                  )}
                  <div className="mt-4">
                    <LeadFormSection />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!leadErrorStatus &&
          (access?.detail.notes.view || access?.detail.tasks.view) && (
            <>
              <div className="right bg-white border border-gray-border rounded-md w-[28%] max-h-[1250px] flex flex-col">
                <TabList
                  onTabChange={() => {}}
                  tabId="tasksPanel"
                  variant="solid"
                >
                  {access?.detail.notes.view && (
                    <TabPane label="Notes" containerClasses="max-h-[1200px]">
                      <NotesSection />
                    </TabPane>
                  )}
                  {access?.detail.tasks.view && (
                    <TabPane label="Tasks" containerClasses="max-h-[1200px]">
                      <TaskSection />
                    </TabPane>
                  )}
                  {access?.detail?.templates?.view && (
                    <TabPane
                      label="Templates"
                      containerClasses="max-h-[1200px]"
                    >
                      <TemplateSection />
                    </TabPane>
                  )}
                </TabList>
              </div>
            </>
          )}
      </div>
    </>
  );
}
