//* Packages Import */
import { useMemo, useState } from "react";

//* Component Imports */
import Select from "@Core/Select";
import Button from "@Core/Button";

//* Actions Import */
import { getAgentTeam } from "@Actions/lead";

//* Hooks Imports */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Utils Import */
import { toastService } from "@Utils/toast";

//* Constants Import */
import { TYPES_OF_AGENT } from "@Constants/bulkActions";

//* Types & Enums Import */
import { LeadTeamType } from "@Types/leads";
import { AGENT_TEAM_LIST, USER_TYPE } from "@Enums/common";
import { IAgentInfo, IAgentName, IAgentOption } from "@Types/dashboard";

//* Assets Imports */
import WarningIcon from "@Assets/icons/warnig-triangle-yellow.png";
import Loader from "@Assets/images/loader.gif";

interface IReassignLeadsProps {
  selectedLeads?: unknown[];
  handleCancel: () => void;
  bulkLeadsReassign: (agentInfo: IAgentInfo) => void;
  isLoading?: boolean;
}

const ReassignLeads = ({
  selectedLeads,
  handleCancel,
  bulkLeadsReassign,
  isLoading,
}: IReassignLeadsProps) => {
  const dispatch = useAppDispatch();
  const { teamData } = useAppSelector((state) => state.lead);
  const { role } = useAppSelector((state) => state.user);
  const [agentType, setAgentType] = useState<LeadTeamType | null>(null);
  const [agentName, setAgentName] = useState<IAgentName | null>();

  const fetchTeamMembers = (slug: LeadTeamType) => {
    if (!slug) return;
    if (!teamData?.[slug]) {
      dispatch(getAgentTeam(slug));
    }
  };

  const handleReassignAgent = () => {
    if (!agentType || !agentName)
      return toastService.notify("error", "Please Select right Agent!");
    bulkLeadsReassign({ type: agentType, info: agentName });
  };

  const filteredAgentOptions = useMemo(() => {
    if (role === USER_TYPE.LQT_HEAD) {
      return TYPES_OF_AGENT.filter(
        (option) =>
          option.slug === (AGENT_TEAM_LIST.LQT_AGENT as AGENT_TEAM_LIST),
      );
    }
    if (role === USER_TYPE.AM_AGENT) {
      return TYPES_OF_AGENT.filter((option) =>
        [AGENT_TEAM_LIST.SALES_AGENT, AGENT_TEAM_LIST.SANCTION_AGENT].includes(
          option.slug as AGENT_TEAM_LIST,
        ),
      );
    }
    return TYPES_OF_AGENT;
  }, []);

  return (
    <div className="w-[470px] h-80">
      <h2 className="text-xl font-semibold mb-4">
        {selectedLeads?.length
          ? `Reassign ${selectedLeads.length} Leads`
          : `Reassign Leads`}
      </h2>
      <div className="mb-4">
        <span className="text-sm mb-2 font-normal block">
          Please choose agent type
        </span>
        <Select
          valueKey="slug"
          labelKey="label"
          placeholder="Select agent type"
          options={filteredAgentOptions}
          setValue={(selectedVal: IAgentOption) => {
            setAgentType(selectedVal.slug);
            fetchTeamMembers(selectedVal.slug);
          }}
        />
      </div>
      <div className="my-6">
        <span className="text-sm mb-2 font-normal block">
          Please choose agent name
        </span>
        <Select
          labelKey="name"
          valueKey="id"
          placeholder="Select agent name"
          options={agentType ? teamData?.[agentType]?.list || [] : []}
          optionsLoading={agentType ? teamData?.[agentType]?.loading : false}
          setValue={(selectedVal: IAgentName) => {
            setAgentName(selectedVal);
          }}
        />
      </div>
      <div className="flex items-center gap-3 bg-yellow-caution-bg p-3 rounded-lg">
        <img src={WarningIcon} alt="warning" />
        <span className="text-xs font-normal text-gray">
          This is an irreversible action. Please reassign with caution.
        </span>
      </div>
      <div className="flex float-end mt-6 gap-3 h-[34px] w-[332px]">
        <Button
          variant="outline"
          classes="w-[160px] h-[34px] flex justify-center items-center"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {isLoading ? (
          <img src={Loader} alt="loading..." className="ml-8" />
        ) : (
          <Button
            variant="error"
            disabled={!agentType || !agentName}
            onClick={handleReassignAgent}
            classes="w-[160px] h-[34px] flex justify-center items-center"
          >
            Reassign agent
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReassignLeads;
