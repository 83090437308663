//* Packages */
import { Outlet } from "react-router-dom";

//* Components */
import Header from "@Components/Header";
import SideBar from "@Components/SideBar";

export default function DashboardLayout() {
  return (
    <div className="relative">
      <Header />
      <div className="flex w-full">
        <div className="w-60 border-r border-gray-border">
          <SideBar />
        </div>
        <div className="min-h-screen bg-crm-bg w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
