//* Packages Imports */
/* eslint-disable react/no-unescaped-entities */
import {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";

//* Components Imports */
import Input from "@Core/Input";
import Select from "@Core/Select";
import CustomButton from "@Core/Button";
import FileInput from "@Core/FileInput";
import PhoneInput from "@Core/PhoneInput";
import CurrencyInput from "@Core/CurrencyInput";
import DynamicFormDatePicker from "@Core/Datepicker/DynamicFormDatePicker";
import RadioWrapper from "@Core/Radio/RadioWrapper";

//* Utils Imports */
import debounce from "@Utils/debounce";

//* Assets Imports */
import Loading from "@Assets/images/loader.gif";

//* Styles Imports */
import Styles from "@Core/FormGenerator/FormGenerator.module.scss";

/** 
 * example of how to make inputFieldsData and defaultValues in parent component,
  const INPUT_FIELDS_DATA = [
      {
        type: "text",
        name: "fullname",
        label: "Name",
        required: true,
      },
      {
        type: "email",
        name: "email",
        label: "Email",
        required: true,
      }]
      const inputFieldsData = useMemo(() => {
        return INPUT_FIELDS_DATA;
      }, [here goes the dependency if inputFieldsData is dependent on any state]);
      const defaultValues = useMemo(() => {
        console.log("getPrefillData");
        return getPrefillData();
      }, []); --> ideally this useMemo should always be empty array

      edge case 1 --> If there is a form that extends itselft after first step (like /list-home/listing-form),
      then that can be handled by modifying the inputFieldsData as a 2D array. [FUTURE SCOPE]

      edge case 2 --> If there is a case where we need to append an input field based on the value of another input field,
      then that can be handled by making the inputFieldsData in parent as state variable and updating
      it based on the value of another input field.

      edge case 3 --> If we have case where we need to change the optionsList of autocomplete input,
      that can be handled similar to edge case 2.

      edge case 4 --> Date picker can handled single date selector as well as range selector. It also has two variants
      range selector with two input fields and range selector with single input field.

      edge case 5 --> As per the requirement throughout UniAcco, the phone input has been divided into two parts
      countryCode and phone input.

      NOTE :-
      1) name for the date type input field should be "date" and for phone input field should be "phone".
      This is important for the validation of these two fields to work properly. Will fix this later :)
*/

type datePickerVariantType = "singleInput" | "twoInput";

type IdatePickerType = "single" | "range";

type ErrorStateObjectType = {
  hasError: boolean;
  current: Record<string, boolean>;
  message: string;
};

export type InputFieldCallbackDataType = {
  currentInputFields?: Array<InputFieldsType>;
  formDataObj?: any;
};

export type InputFieldsType = {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  subtext?: string;
  options?: any;
  required?: boolean;
  pattern?: string;
  defaultValue?: unknown;
  datePickerVariant?: datePickerVariantType | string;
  datePickerType?: IdatePickerType;
  disableMonthSelection?: boolean;
  disableYearSelection?: boolean;
  helperText?: string;
  valueKey?: string;
  labelKey?: string;
  tooltip?: string;
  callback?: (formObj: InputFieldCallbackDataType) => void;
  disablePreviousDates?: boolean;
  showClearDates?: boolean;
  disabled?: boolean;
  countryCodeName?: string;
  countryCodePlaceholder?: string;
  variant?: "singleSelect" | "multiSelect";
  showTime?: boolean;
  canSearch?: boolean;
  fileInputAcceptableExtensions?: string;
  multipleFilesAllowed?: boolean;
  isFloat?: boolean;
  disableDatesAfter?: Date;
  disabledDates?: string[];
  infoText?: string;
  optionPosition?: "top" | "bottom";
  radioOptionLayout?: "row" | "column";
};
type IFormGenerator = {
  inputFieldsData: Array<InputFieldsType>;
  onSubmit?: (formData: any) => void;
  defaultValues?: Record<string, any>;
  gridCol?: number;
  errorObj?: {
    hasError: boolean;
    current: Record<string, boolean>;
    message: string;
  };
  submitCtaText?: string;
  hideSubmitButton?: boolean;
  customFooter?: ReactNode;
  disableSubmitBtn?: boolean;
  labelPosition?: "left" | "top";
  buttonClasses?: string;
};

const FormGenerator = ({
  inputFieldsData,
  defaultValues = {},
  onSubmit,
  gridCol = 1,
  errorObj = {
    hasError: false,
    current: {},
    message: "",
  },
  submitCtaText = "Save",
  hideSubmitButton = false,
  customFooter,
  disableSubmitBtn,
  labelPosition = "left",
  buttonClasses,
}: IFormGenerator) => {
  enum INPUT_TYPES {
    TEXT = "text",
    EMAIL = "email",
    PHONE = "phone",
    SELECT = "select",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    DATE = "date",
    NUMBER = "number",
    FILE = "file",
    CURRENCYINPUT = "currencyInput",
  }
  const formData: any = useRef(null);
  const mandatoryFieldsRef = useRef<Array<string>>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [errorStateObj, setErrorStateObj] = useState<ErrorStateObjectType>({
    hasError: false,
    current: {},
    message: "",
  });

  const colClass = `grid-cols-${gridCol}`;

  const onChangeHandler = useCallback(
    debounce((value: any, name: string, inputData: InputFieldsType) => {
      setIsDirty(true);
      if (inputData?.type === INPUT_TYPES.CHECKBOX) {
        const selectedValues = formData.current[name] || [];
        if (selectedValues.includes(value)) {
          formData.current = {
            ...formData.current,
            [name]: selectedValues.filter(
              (val: string | number) => val !== value,
            ),
          };
        } else {
          formData.current = {
            ...formData.current,
            [name]: [...selectedValues, value],
          };
        }
      } else {
        formData.current = {
          ...formData.current,
          [name]: value,
        };
      }

      if (inputData?.callback) {
        inputData.callback({
          currentInputFields: inputFieldsData,
          formDataObj: formData.current,
        });
      }
    }, 500),
    [inputFieldsData],
  );

  function handleInputChange(e: any, name: string, inputData: InputFieldsType) {
    const isFileInput = inputData?.type === INPUT_TYPES.FILE;
    let value;
    if (isFileInput) {
      value = e.target.files || ([] as unknown as FileList);
    } else {
      if (e.target.value.trim() !== e.target.value) {
        setErrorStateObj({
          hasError: true,
          current: {
            ...errorStateObj.current,
            [name]: true,
          },
          message: "Please remove leading and trailing spaces",
        });
        return;
      } else {
        setErrorStateObj({
          hasError: false,
          current: {
            ...errorStateObj.current,
            [name]: false,
          },
          message: "",
        });
      }

      value = e.target.value;
    }
    onChangeHandler(value, name, inputData);
  }

  function handleOnSelect(
    value: unknown,
    name: string,
    inputData: InputFieldsType,
  ) {
    onChangeHandler(value, name, inputData);
  }

  useEffect(() => {
    if (errorObj.hasError) {
      setErrorStateObj(errorObj);
    }
  }, [errorObj]);

  function generateField(inputData: InputFieldsType) {
    switch (inputData.type) {
      case INPUT_TYPES.TEXT:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <Input
              label={inputData.label}
              labelPosition={labelPosition}
              type="text"
              name={inputData.name}
              placeholder={inputData?.placeholder}
              onChange={(e) => handleInputChange(e, inputData.name, inputData)}
              defaultValue={
                defaultValues?.[inputData.name] &&
                defaultValues?.[inputData.name]?.toString()?.trim()
              }
              disabled={inputData?.disabled || false}
              helperText={inputData?.subtext}
              pattern={inputData?.pattern}
              mandatory={inputData?.required}
              infoText={inputData?.infoText}
            />
          </div>
        );
      case INPUT_TYPES.NUMBER:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <Input
              label={inputData.label}
              labelPosition={labelPosition}
              type="number"
              name={inputData.name}
              placeholder={inputData?.placeholder}
              onChange={(e) => handleInputChange(e, inputData.name, inputData)}
              defaultValue={defaultValues[inputData.name]}
              disabled={inputData?.disabled || false}
              helperText={inputData?.subtext}
              infoText={inputData?.helperText}
              mandatory={inputData?.required}
              step={inputData?.isFloat ? "any" : "1"}
            />
          </div>
        );
      case INPUT_TYPES.EMAIL:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <Input
              label={inputData.label}
              labelPosition={labelPosition}
              type="email"
              name={inputData.name}
              placeholder={inputData?.placeholder}
              onChange={(e) => handleInputChange(e, inputData.name, inputData)}
              defaultValue={defaultValues[inputData.name]}
              disabled={inputData?.disabled || false}
              helperText={inputData?.subtext}
              mandatory={inputData?.required}
            />
          </div>
        );
      case INPUT_TYPES.PHONE:
        return (
          <div data-cy="phone-container" className={Styles.inputFormContainer}>
            <div
              className={clsx({
                [Styles.errorBorder]:
                  errorStateObj.current[inputData?.countryCodeName || ""] ||
                  errorStateObj.current[inputData.name],
              })}
            >
              <PhoneInput
                labelPosition={labelPosition}
                label={inputData.label}
                placeholder={inputData?.placeholder}
                countryCodePlaceholder={inputData?.countryCodePlaceholder}
                countryCodeDefaultValue={
                  inputData?.countryCodeName &&
                  defaultValues[inputData.countryCodeName]
                }
                inputDefaultValue={defaultValues[inputData.name]}
                onCountryCodeSelect={(value) =>
                  handleOnSelect(
                    value,
                    inputData?.countryCodeName || "",
                    inputData,
                  )
                }
                onInputChange={(e) =>
                  handleInputChange(e, inputData.name, inputData)
                }
                valueKey={inputData?.valueKey}
                labelKey={inputData?.labelKey}
                mandatory={inputData.required}
                disabled={inputData?.disabled || false}
              />
              {inputData?.subtext && (
                <span className={Styles.subtext}>{inputData?.subtext}</span>
              )}
            </div>
          </div>
        );
      case INPUT_TYPES.SELECT:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <Select
              label={inputData.label}
              options={inputData.options || []}
              variant={inputData.variant}
              setValue={(option: any) =>
                handleOnSelect(option, inputData?.name || "", inputData)
              }
              valueKey={inputData?.valueKey}
              labelKey={inputData?.labelKey}
              defaults={defaultValues[inputData.name]}
              disabled={inputData?.disabled || false}
              labelPosition={labelPosition}
              mandatory={inputData?.required}
              canSearch={inputData?.canSearch}
              optionPosition={inputData?.optionPosition}
            />
            {inputData?.subtext && (
              <span className={Styles.subtext}>{inputData?.subtext}</span>
            )}
          </div>
        );
      case INPUT_TYPES.RADIO:
        return (
          <div className={Styles.inputFormContainer}>
            <RadioWrapper
              label={inputData.label}
              options={inputData.options}
              name={inputData.name}
              checked={defaultValues[inputData.name]}
              onChange={(val: any) =>
                handleOnSelect(val, inputData.name, inputData)
              }
              disabled={inputData?.disabled}
              mandatory={inputData?.required}
              radioOptionLayout={inputData?.radioOptionLayout}
            />
          </div>
        );
      case INPUT_TYPES.CHECKBOX:
        return (
          <div className={Styles.inputFormContainer}>
            <label htmlFor={inputData.name}>{inputData.label}</label>
            {inputData?.options &&
              inputData.options.map((option: any) => (
                <label
                  htmlFor={inputData.name}
                  key={option.value}
                  className={Styles.radioAndCheckboxLabel}
                  onClick={() =>
                    handleOnSelect(option.value, inputData.name, inputData)
                  }
                >
                  <input
                    type="checkbox"
                    name={inputData.name}
                    value={option.value}
                  />
                  {option.label}
                </label>
              ))}
          </div>
        );
      case INPUT_TYPES.DATE:
        return (
          <DynamicFormDatePicker
            name={inputData?.name}
            setSelectedDateObj={(obj) =>
              handleOnSelect(obj, inputData.name, inputData)
            }
            type={inputData.datePickerType}
            defaultSelectedDateObj={defaultValues[inputData.name]}
            placeholder={inputData?.placeholder}
            hasError={errorStateObj.current[inputData.name]}
            disablePreviousDates={inputData?.disablePreviousDates}
            disableMonthSelection={inputData?.disableMonthSelection}
            disableYearSelection={inputData?.disableYearSelection}
            disableDatesAfter={inputData?.disableDatesAfter}
            disabledDates={inputData?.disabledDates}
            disabled={inputData?.disabled || false}
            labelPosition={labelPosition}
            mandatory={inputData?.required}
            label={inputData?.label}
            showTime={inputData?.showTime}
            helperText={inputData?.helperText}
          />
        );
      case INPUT_TYPES.FILE:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <FileInput
              labelPosition={labelPosition}
              className="hidden"
              label={inputData.label}
              name={inputData.name}
              onChange={(e) => handleInputChange(e, inputData.name, inputData)}
              disabled={inputData?.disabled || false}
              helperText={inputData?.subtext}
              errorEnabled={errorStateObj.current[inputData.name]}
              mandatory={inputData?.required}
              accept={inputData?.fileInputAcceptableExtensions || "*"}
              multiple={inputData?.multipleFilesAllowed || false}
              defaultValue={defaultValues?.[inputData.name] || []}
            />
          </div>
        );
      case INPUT_TYPES.CURRENCYINPUT:
        return (
          <div
            className={clsx(Styles.inputFormContainer, {
              [Styles.errorBorder]: errorStateObj.current[inputData.name],
            })}
          >
            <CurrencyInput
              labelPosition={labelPosition}
              label={inputData.label}
              name={inputData.name}
              placeholder={inputData?.placeholder}
              onChange={(e) => handleInputChange(e, inputData.name, inputData)}
              defaultValue={defaultValues[inputData.name]}
              disabled={inputData?.disabled || false}
              helperText={inputData?.subtext}
              mandatory={inputData?.required}
            />
          </div>
        );
      default:
        return null;
    }
  }

  function isMandatoryFieldEmpty() {
    const errorObj: Record<string, boolean> = {};
    let hasError = false;
    for (const key of mandatoryFieldsRef.current) {
      /*  if (
            key === "date" &&
            !(
              (formData.current[key]?.startDate &&
                formData.current[key]?.endDate) ||
              formData.current[key]?.selectedDate
            )
          ) {
            errorObj[key] = true;
            hasError = true;
          } else
          */
      // {
      // }

      if (
        !formData?.current?.[key]?.toString() ||
        formData?.current?.[key]?.toString()?.length === 0 ||
        (typeof formData?.current?.[key] !== "number" &&
          Object.keys(formData?.current?.[key])?.length === 0)
      ) {
        errorObj[key] = true;
        hasError = true;
      }
    }
    setErrorStateObj({
      ...errorStateObj,
      hasError,
      current: errorObj,
      message: "Please fill the mandatory fields",
    });

    return hasError;
  }

  function handleFormSubmit(e: any) {
    e.preventDefault();
    if (isMandatoryFieldEmpty()) {
      return;
    }
    if (isDirty) setIsDirty(false);
    onSubmit?.(formData.current);
  }

  useEffect(() => {
    if (inputFieldsData.length > 0) {
      // Update mandatory fields and remove old selected values
      const mandatoryFields: Array<string> = [];
      const formSelectedFields: Record<string, string | number> = {};

      inputFieldsData.forEach((data) => {
        if (data?.required) {
          if (data?.countryCodeName) {
            mandatoryFields.push(data.countryCodeName);
          }
          mandatoryFields.push(data.name);
        }
        if (formData.current?.[data.name]?.toString()) {
          formSelectedFields[data.name] = formData.current[data.name];
          if (data?.countryCodeName) {
            formSelectedFields[data.countryCodeName] =
              formData.current[data.countryCodeName];
          }
        }
      });
      mandatoryFieldsRef.current = mandatoryFields;
      formData.current = formSelectedFields;
    }
  }, [inputFieldsData]);

  useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      formData.current = { ...formData.current, ...defaultValues };
    }
  }, [defaultValues]);

  const fieldGenerator = useMemo(
    () =>
      inputFieldsData.map((inputData, index) => (
        <Fragment key={`${inputData.name}-${index}`}>
          {generateField(inputData)}
        </Fragment>
      )),
    [inputFieldsData, errorStateObj.current, defaultValues],
  );

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <div className={clsx(Styles.container, colClass)}>{fieldGenerator}</div>
      {!hideSubmitButton && (
        <div className={clsx(Styles.submitBtnContainer, buttonClasses)}>
          {errorStateObj.hasError && (
            <span className={Styles.error}>{errorStateObj.message}</span>
          )}
          <div className="flex gap-2">
            {disableSubmitBtn && (
              <div className="flex items-center text-gray text-sm">
                <img src={Loading} alt="loading" className="w-7 h-7" />
                <span>Saving changes...</span>
              </div>
            )}
            <CustomButton
              variant="solid"
              type="submit"
              font="sm"
              disabled={disableSubmitBtn || !isDirty}
              classes={clsx("w-[160px]", {
                "cursor-not-allowed": disableSubmitBtn || !isDirty,
              })}
            >
              {submitCtaText}
            </CustomButton>
          </div>
        </div>
      )}
      {customFooter && customFooter}
    </form>
  );
};

export default memo(FormGenerator);
