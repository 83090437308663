//* Package Imports */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Service Imports */
import { getConversion } from "@Actions/lead/logs";
import API from "@Axios/main";
import { toastService } from "@Utils/toast";
import { getLeadDetails } from "@Actions/lead";
import {
  replaceLoanApplication,
  setHasMoreTasks,
  setLeadDataLoading,
  setLoanApplications,
  setLoanApplicationsLoading,
} from "@Slice/LeadSlice";
import { LEAD_DETAILS_SECTION, UPDATE_LOAN_APPLICATION } from "@Constants/urls";

//* Mapper Imports */
import { LOAN_APPLICATION_MAPPER } from "@Mappers/lead";

//* Types Imports */
import { ILeadLoanApplication } from "@Types/leads";
import { IApplicationPayload, ILeadTasks } from "@Types/common";

const _API = new API();

//* Get Loan Applications */
export const getLoanApplications = createAsyncThunk(
  "task/getLoanApplications",
  async (body: { leadId: number }, { dispatch }) => {
    try {
      dispatch(setLoanApplicationsLoading(true));
      const res: ILeadLoanApplication[] = await _API.get(
        LEAD_DETAILS_SECTION(body.leadId, "loan-applications"),
        LOAN_APPLICATION_MAPPER,
      );

      if (res?.length) {
        dispatch(setLoanApplications(res));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error getting applications!",
      );
      dispatch(setHasMoreTasks(false));
    } finally {
      dispatch(setLoanApplicationsLoading(false));
    }
  },
);

export interface ILeadApplicationUpdatePayload {
  data: IApplicationPayload;
  leadId: number;
  applicationId?: number;
  callback?: () => void;
}

//* Update Loan Applications */
export const statusUpdateCheck = createAsyncThunk(
  "task/statusUpdateCheck",
  async (body: ILeadApplicationUpdatePayload) => {
    try {
      await _API.patch(
        LEAD_DETAILS_SECTION(
          body.leadId,
          `loan-applications/${body.applicationId}/status-check`,
        ),
        body.data,
      );
    } catch (err: any) {
      console.error("statusUpdateCheck", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error updating application!",
      );
      throw err;
    }
  },
);

export const updateLoanApplication = createAsyncThunk(
  "task/updateLoanApplication",
  async (body: ILeadApplicationUpdatePayload, { dispatch }) => {
    try {
      const res: ILeadTasks = await _API.patch(
        LEAD_DETAILS_SECTION(
          body.leadId,
          `loan-applications/${body.applicationId}`,
        ),
        body.data,
        LOAN_APPLICATION_MAPPER,
      );

      if (res?.id) {
        dispatch(replaceLoanApplication(res));
        toastService.notify("success", "Application has been updated!");
        body.callback?.();
        await dispatch(getConversion(body.leadId));
      }
    } catch (err: any) {
      let errorMessage = "";

      const errorData = err.response?.data;

      if (errorData && Object.keys(errorData).length) {
        Object.entries(errorData).forEach(([key, value]) => {
          errorMessage += `${key} - ${value}\n`;
        });
      }

      if (err instanceof AxiosError && !errorMessage) {
        errorMessage = errorData?.message || err.message;
      }

      toastService.notify(
        "error",
        errorMessage || "Error updating application!",
      );
      throw err;
    }
  },
);

export const reassignLender = createAsyncThunk(
  "lead/updateLender",
  async (payload: any, { dispatch }) => {
    const { leadId, applicationId, ...rest } = payload;
    dispatch(setLeadDataLoading(true));
    try {
      await _API.patch(UPDATE_LOAN_APPLICATION(leadId, applicationId), rest);
      dispatch(getLeadDetails(leadId));
      toastService.notify("success", "Lead re-assigned successfully!");
    } catch (err: any) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error re-assigning lender!",
      );
    }
    dispatch(setLeadDataLoading(false));
  },
);
