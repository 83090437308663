//* Types Import */
import {
  BULK_ACTION_TYPE,
  BULK_COMPLETION_STATUS,
  LEAD_STATUS,
} from "@Enums/dashboard";
import { IAgentOption, IBulkActionConfig, ILeadStatus } from "@Types/dashboard";

//* Assets Import */
import GreenTick from "@Assets/icons/green-check-fill-black.png";
import Warning from "@Assets/icons/warning.png";
import Error from "@Assets/icons/remove-red-fill.png";
import { AGENT_TEAM_LIST, StatusKeyEnum } from "@Enums/common";

export const TEMPLATES_CSV =
  "https://assets-stg.unicreds.com/uploads/bulk_uploads_docs/sample.csv";

export const BULK_ACTION_CONFIG: Record<BULK_ACTION_TYPE, IBulkActionConfig> = {
  [BULK_ACTION_TYPE.BULK_REASSIGN]: {
    title: "Reassign using CSV",
    description: "Please upload a CSV file to read & re-assign leads",
    showWarning: false,
    primaryButtonCTA: "Proceed",
  },
  [BULK_ACTION_TYPE.BULK_STATUS]: {
    title: "Bulk Status Change",
    description: "Please upload CSV file to update lead status.",
    showWarning: true,
    warningMessage:
      "Ensure you're uploading a CSV file containing leads with the same statuses.",
    primaryButtonCTA: "Choose Status",
  },
};

export const LEAD_STATUS_LIST: ILeadStatus[] = [
  { value: StatusKeyEnum.OPPORTUNITY, label: LEAD_STATUS.OPPORTUNITY },
  { value: StatusKeyEnum.CREATED, label: LEAD_STATUS.CREATED },
  { value: StatusKeyEnum.PRE_QUALIFIED, label: LEAD_STATUS.PREQUALIFIED },
];

export const STATUS_MAPPING: Record<
  LEAD_STATUS,
  { value: StatusKeyEnum; label: string }
> = {
  [LEAD_STATUS.PREQUALIFIED]: {
    value: StatusKeyEnum.CREATED,
    label: "Created",
  },
  [LEAD_STATUS.CREATED]: { value: StatusKeyEnum.LOST, label: "Lost" },
  [LEAD_STATUS.OPPORTUNITY]: {
    value: StatusKeyEnum.CONTACTED,
    label: "Contacted",
  },
};

export const BULK_COMPLETION_STATUS_CONFIG = {
  [BULK_COMPLETION_STATUS.SUCCESS]: {
    label: `${BULK_COMPLETION_STATUS.SUCCESS}!`,
    icon: GreenTick,
    color: "text-success",
  },
  [BULK_COMPLETION_STATUS.PARTIALLY_CONVERTED]: {
    label: BULK_COMPLETION_STATUS.PARTIALLY_CONVERTED,
    icon: Warning,
    color: "text-warning",
  },
  [BULK_COMPLETION_STATUS.ERROR]: {
    label: BULK_COMPLETION_STATUS.ERROR,
    icon: Error,
    color: "text-error",
  },
};

export const TYPES_OF_AGENT: IAgentOption[] = [
  { label: "Login Agent", slug: AGENT_TEAM_LIST.SALES_AGENT },
  { label: "LQT AGENT", slug: AGENT_TEAM_LIST.LQT_AGENT },
  { label: "Sanction Agent", slug: AGENT_TEAM_LIST.SANCTION_AGENT },
];
