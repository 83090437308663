//* Package Imports */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Service Imports */
import API from "@Axios/main";
import { getLoanApplications } from "@Actions/lead/LoanApplication";

//* Slices //
import {
  setConversionData,
  setDisbursalData,
  setDisbursalSaveLoader,
  setTimelineLoading,
  setDisbursalLoading,
  setConversionLoading,
  setTimelineLogs,
  setDisbursalForm,
  updateDisbursalData,
  setReviewDocModal,
  setReviewDisbursalLoader,
  setRepeatedLeadLoading,
  setRepeatLeadsData,
  updateDisbursalDocumentStatus,
} from "@Slice/LeadSlice";

//* URL Imports */
import {
  GET_TIMELINE_LOGS,
  GET_DISBURSAL_LOGS,
  GET_CONVERSION_LOGS,
  DISBURSAL_API,
  UPDATE_DISBURSAL_API,
  REVIEW_DISBURSMENT_DOCUMENT,
  REPEATED_LEADS,
} from "@Constants/urls";

//* Utility Imports */
import { toastService } from "@Utils/toast";
import { getStructuredDate } from "@Utils/timeUtils";
import { transformIntoTableRenderData } from "@Actions/helper";
import { RootState } from "@Store/index";

//* Data Imports */
import {
  CONVERSION_LOGS_MAPPER,
  DISBURSAL_TABLE_DATA_MAPPER,
  REVIEW_DOCUMENT,
  REPEATED_LEAD_LOGS_MAPPER,
  TIMELINE_LOGS_MAPPER,
} from "@Mappers/timeline";
import {
  CONVERSION_TABLE_HEADERS,
  DISBURSAL_TABLE_HEADERS,
  REPEATED_LEADS_TABLE_HEADERS,
} from "@Constants/logs";

//* Types Import //
import {
  IConversion,
  IDisburalData,
  IDisburalDetails,
  IDisbursalDisplayData,
  IDisbursalReviewDocument,
  ITimelineLogs,
} from "@Types/timeline";

const logsApiV2 = new API();

export const getTimeline = createAsyncThunk(
  "lead/timelineLogs",
  async (leadId: number, { dispatch }) => {
    dispatch(setTimelineLoading(true));
    try {
      const response: ITimelineLogs = await logsApiV2.get(
        GET_TIMELINE_LOGS(leadId),
        TIMELINE_LOGS_MAPPER,
      );
      dispatch(setTimelineLogs(response));
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while fetching timeline logs!",
      );
    } finally {
      dispatch(setTimelineLoading(false));
    }
  },
);

export const getDisbursal = createAsyncThunk(
  "lead/disbursal",
  async (leadId: number, { dispatch }) => {
    dispatch(setDisbursalLoading(true));
    try {
      const response: IDisburalData[] = await logsApiV2.get(
        GET_DISBURSAL_LOGS(leadId),
        DISBURSAL_TABLE_DATA_MAPPER,
      );
      const disbursalData = transformIntoTableRenderData(
        response,
        DISBURSAL_TABLE_HEADERS,
      );
      dispatch(setDisbursalData(disbursalData));
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while fetching disbursal data!",
      );
    } finally {
      dispatch(setDisbursalLoading(false));
    }
  },
);

export const getConversion = createAsyncThunk(
  "lead/loanApplication/conversion",
  async (leadId: number, { dispatch }) => {
    dispatch(setConversionLoading(true));
    try {
      const response: IConversion[] = await logsApiV2.get(
        GET_CONVERSION_LOGS(leadId),
        CONVERSION_LOGS_MAPPER,
      );
      if (Object.entries(response).length > 0) {
        const conversionData = transformIntoTableRenderData(
          response,
          CONVERSION_TABLE_HEADERS,
        );
        dispatch(setConversionData(conversionData));
      }
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while fetching convertion!",
      );
    } finally {
      dispatch(setConversionLoading(false));
    }
  },
);

export const createDisbursal = createAsyncThunk(
  "leads/createDisbursal",
  async (body: { leadId: number; payload: IDisburalDetails }, { dispatch }) => {
    try {
      const disbursalData = {
        currency: body.payload.currency,
        disbursed_amount: body.payload.disbursedAmount,
        loan_amount_approved: body.payload.loanAmountApproved,
        roi: body.payload.roi,
        disbursal_date: getStructuredDate(
          body.payload.disbursalDate?.selectedDate,
        ),
        next_tranche_date:
          getStructuredDate(body.payload.nextTrancheDate?.selectedDate) ||
          undefined,
        file_path: body.payload.filePath,
      };
      dispatch(setDisbursalSaveLoader(true));
      const response: IDisburalDetails = await logsApiV2.post(
        DISBURSAL_API(body.leadId),
        disbursalData,
        DISBURSAL_TABLE_DATA_MAPPER,
      );
      if (response?.id) {
        const updatedData: IDisbursalDisplayData[] =
          transformIntoTableRenderData([response], DISBURSAL_TABLE_HEADERS);
        dispatch(updateDisbursalData(updatedData[0]));
        dispatch(setDisbursalForm(false));
        toastService.notify("success", "Disbursal Added successfully!");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while creating disbursal!",
      );
    } finally {
      dispatch(setDisbursalSaveLoader(false));
    }
  },
);

export const updateDisbursal = createAsyncThunk(
  "leads/updateDisbursal",
  async (
    body: { leadId: number; disbursalId: number; payload: IDisburalDetails },
    { dispatch },
  ) => {
    try {
      dispatch(setDisbursalSaveLoader(true));
      const disbursalData = {
        currency: body.payload.currency,
        disbursed_amount: body.payload.disbursedAmount,
        loan_amount_approved: body.payload.loanAmountApproved,
        roi: body.payload.roi,
        disbursal_date:
          getStructuredDate(
            body.payload.disbursalDate?.selectedDate ||
              body.payload.disbursalDate?.startDate,
          ) || undefined,
        next_tranche_date:
          getStructuredDate(
            body.payload.nextTrancheDate?.selectedDate ||
              body.payload.nextTrancheDate?.startDate,
          ) || undefined,
        file_path: body.payload.filePath,
      };
      const response: IDisburalDetails = await logsApiV2.patch(
        UPDATE_DISBURSAL_API(body.leadId, body.disbursalId),
        disbursalData,
        DISBURSAL_TABLE_DATA_MAPPER,
      );

      if (response?.id) {
        dispatch(getLoanApplications({ leadId: body.leadId }));
        const updatedData: IDisbursalDisplayData[] =
          transformIntoTableRenderData([response], DISBURSAL_TABLE_HEADERS);
        dispatch(updateDisbursalData(updatedData[0]));
        dispatch(setDisbursalForm(false));
        toastService.notify("success", "Disbursal Updated successfully!");
      } else {
        throw new Error("Failed to update disbursal");
      }
    } catch (error: any) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while updating disbursal!",
      );
    } finally {
      dispatch(setDisbursalSaveLoader(false));
    }
  },
);

export const reviewDocument = createAsyncThunk(
  "disbursal/reviewDocument",
  async (
    body: {
      documentId: number;
      status: string;
      comment?: string;
    },
    { dispatch, getState },
  ) => {
    const { lead } = getState() as RootState;
    dispatch(setReviewDisbursalLoader(body.status));
    try {
      const payload = {
        status: body.status,
        comment: body.comment,
      };
      const response: IDisbursalReviewDocument = await logsApiV2.patch(
        REVIEW_DISBURSMENT_DOCUMENT(body.documentId),
        payload,
        REVIEW_DOCUMENT,
      );
      if (response?.id) {
        dispatch(setReviewDocModal(false));
        dispatch(updateDisbursalDocumentStatus(response));
        dispatch(getLoanApplications({ leadId: lead.leadData?.id as number }));
        dispatch(getDisbursal(lead.leadData?.id as number));
      }
      toastService.notify("success", "Document Status Updated");
    } catch (error: any) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while fetching convertion!",
      );
    } finally {
      dispatch(setReviewDisbursalLoader(""));
    }
  },
);

export const getRepeatedLeads = createAsyncThunk(
  "lead/repeatedLeads",
  async (leadId: number, { dispatch }) => {
    dispatch(setRepeatedLeadLoading(true));
    try {
      const response = await logsApiV2.get(
        REPEATED_LEADS(leadId),
        REPEATED_LEAD_LOGS_MAPPER,
      );

      const repeatedLead = transformIntoTableRenderData(
        response,
        REPEATED_LEADS_TABLE_HEADERS,
      );

      dispatch(setRepeatLeadsData(repeatedLead));
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setRepeatedLeadLoading(false));
    }
  },
);
