//* Assets */
import Browser from "@Assets/icons/browser.png";
import Growth from "@Assets/icons/growth.png";
import AddUser from "@Assets/icons/add-user.png";
import Dashboard from "@Assets/icons/dashboardOutline.png";

//* Packages */
import { createSlice } from "@reduxjs/toolkit";
// * Enums */
import { USER_TYPE } from "@Enums/common";

const initialState = {
  navigationLinks: [
    {
      id: "add-lead",
      label: "Add Lead",
      icon: AddUser,
      link: "/new-lead",
      hiddenRoles: [USER_TYPE.LENDER],
    },
    {
      id: "home",
      label: "Home",
      icon: Growth,
      link: "/",
      hiddenRoles: [USER_TYPE.LENDER],
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: Dashboard,
      link: "/dashboard/bulk-assignment",
      hiddenRoles: [
        USER_TYPE.LENDER,
        USER_TYPE.AGENT,
        USER_TYPE.SANCTION_AGENT,
        USER_TYPE.LQT_AGENT,
        USER_TYPE.RG_AGENT,
      ],
    },
    {
      id: "website",
      label: "Website",
      icon: Browser,
      link: "https://www.unicreds.com",
      hiddenRoles: [USER_TYPE.LENDER],
    },
  ],
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {},
});

export default navigationSlice.reducer;
