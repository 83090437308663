//* Packages */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Slices */
import {
  addLeadTag,
  removeLeadTag,
  setLeadData,
  setLeadDataLoading,
  setTagsLoading,
  unshiftNotes,
  addNotes,
  setNotesUpcomingLink,
  resetNotes,
  setHasMoreNotes,
  setNoteCreateDisabled,
  setLeadAssignees,
  addTasks,
  setTasksUpcomingLink,
  setHasMoreTasks,
  setActiveTaskCount,
  setCompletedTaskCount,
  setTaskFilters,
  replaceTask,
  resetTaskList,
  setTaskWriteDisabled,
  setTaskCategories,
  unshiftTasks,
  setDocumentsLoading,
  setDocuments,
  updateTeamData,
  setUnMaskingPhone,
  setUnMaskingEmail,
  setPhoneIsMasked,
  setEmailIsMasked,
  setUploadingDocument,
  setLeadErrorStatus,
  setUnMaskingLoadingVariable,
  setAltPhoneIsMasked,
  setUnMaskingAltPhone,
  setTemplateLoading,
  setTemplates,
} from "@Slice/LeadSlice";
import { setStatusList } from "@Slice/DashboardSlice";

//* Utils */
import {
  LEAD_DETAILS,
  TEAM_MEMBERS,
  UPDATE_TAGS,
  GET_NOTES,
  GET_LEAD_ASSIGNEES,
  GET_TASKS,
  GET_TASK_CATEGORIES,
  DOCUMENTS,
  UPDATE_DOCUMENT,
  UNMASKING,
  LEAD_STATUS,
  COPY_LEAD_DETAILS,
  PRESIGNED_DOCUMENT_LINKS,
  LEAD_TEMPLATES,
  Forward_Lead,
} from "@Constants/urls";
import { transformLeadAssignees, transformLeadCreatedBy } from "@Utils/lead";
import API from "@Axios/main";
import { convertToTitleCase } from "@Utils/common";
import { toastService } from "@Utils/toast";

//* Data Imports */
import { RootState } from "@Store/index";
import {
  LEAD_DETAILS_MAPPER,
  NOTE_MAPPER,
  LEAD_ASSIGNEE_MAPPER,
  TASK_MAPPER,
  DOCUMENTS_MAPPER,
  COPY_LEAD_DETAILS_MAPPER,
  TEMPLATES_MAPPER,
} from "@Mappers/lead";

//* Types & Enums */
import {
  ILeadAssigneeUsers,
  INoteDetails,
  IPaginatedResponse,
  StatusKey,
  ILeadTasks,
  ILeadTaskUpdatePayload,
  ILeadTaskCategory,
  MaskableFields,
  PresignedResponse,
  ITemplate,
} from "@Types/common";
import { ILead, LeadTeamType, Agent, MaskedInfo } from "@Types/leads";
import { TAG_ACTION } from "@Enums/common";
import { LoanDocs } from "@Types/documents";

export interface IUpdateLeadStatus {
  id: number;
  status: StatusKey;
  lost_reason?: string;
}

interface INotesCreatePayload {
  note: string;
  tagged_agents: number[] | null;
}

interface IStatusResponse {
  data: StatusKey[];
}

const _API = new API();

export const getLeadDetails = createAsyncThunk(
  "lead/details",
  async (id: number, { dispatch }) => {
    dispatch(setLeadDataLoading(true));
    try {
      const [leadDetails, { data: leadStatuses }] = await Promise.all([
        _API.get<ILead>(`${LEAD_DETAILS}/${id}`, LEAD_DETAILS_MAPPER),
        _API.get<IStatusResponse>(LEAD_STATUS),
      ]);

      const _leadDetails = {
        ...leadDetails,
        leadinfo: {
          ...leadDetails.leadinfo,
          lostReason: convertToTitleCase(
            leadDetails?.leadinfo?.lostReason,
            "-",
          ),
        },
      };

      dispatch(setLeadData(_leadDetails));

      if (leadStatuses) {
        dispatch(setStatusList(leadStatuses));
      }
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 403) {
        dispatch(setLeadErrorStatus("NO_ACCESS"));
      }
      if (error.response && error.response.status === 404) {
        dispatch(setLeadErrorStatus("NOT_FOUND"));
      }
      toastService.notify("error", "Something went wrong, please try again!");
    } finally {
      dispatch(setLeadDataLoading(false));
    }
  },
);

export const updateLeadDetails = createAsyncThunk(
  "lead/updateDetails",
  async (body: { leadId: number; payload: any }, { dispatch }) => {
    dispatch(setLeadDataLoading(true));
    try {
      const leadDetails = await _API.patch(
        `${LEAD_DETAILS}/${body.leadId}`,
        body.payload,
        LEAD_DETAILS_MAPPER,
      );
      dispatch(setLeadData(leadDetails));
    } catch (error: any) {
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setLeadDataLoading(false));
    }
  },
);

export const updateLeadStatus = createAsyncThunk(
  "lead/updateStatus",
  async (payload: IUpdateLeadStatus, { dispatch, getState }) => {
    dispatch(setLeadDataLoading(true));

    const { id, ...rest } = payload;

    const { user } = getState() as RootState;

    try {
      const res: ILead = await _API.patch(
        `${LEAD_DETAILS}/${id}`,
        { ...rest },
        LEAD_DETAILS_MAPPER,
      );

      dispatch(setLeadData(res));
      if (user.access?.detail.templates.view)
        dispatch(
          getTemplates({
            leadId: id,
          }),
        );
      toastService.notify("success", "Status updated successfully!");
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setLeadDataLoading(false));
    }
  },
);

export const updateTags = createAsyncThunk(
  "lead/updateTags",
  async (payload: any, { dispatch }) => {
    dispatch(setTagsLoading(true));
    try {
      await _API.post(UPDATE_TAGS(payload.leadId), {
        tag_code: payload.tag.code,
        action: payload.action,
      });
      if (payload.action === TAG_ACTION.ADD) dispatch(addLeadTag(payload.tag));
      else dispatch(removeLeadTag(payload.tag));
    } catch (e) {
      console.error(e);
      toastService.notify("error", "Something went wrong, please try again!");
    } finally {
      dispatch(setTagsLoading(false));
    }
  },
);

export const getAgentTeam = createAsyncThunk(
  "lead/agents",
  async (agentType: LeadTeamType, { dispatch }) => {
    dispatch(updateTeamData({ [agentType]: { loading: true } }));
    try {
      const res: Agent[] = await _API.get(
        TEAM_MEMBERS,
        {},
        {
          params: {
            agent_type: agentType,
          },
        },
      );
      if (res) {
        dispatch(
          updateTeamData({ [agentType]: { loading: false, list: res } }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
);

export const createNewNote = createAsyncThunk(
  "lead/createNewNote",
  async (
    body: { data: INotesCreatePayload; leadId: number; callback?: () => void },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.notes.isCreateDisabled) return;
      dispatch(setNoteCreateDisabled(true));

      const response: INoteDetails = await _API.post(
        GET_NOTES(body.leadId),
        body.data,
        NOTE_MAPPER,
      );

      if (response?.id) {
        dispatch(unshiftNotes(response));
        toastService.notify("success", "Note created successfully!");
        body.callback?.();
      } else {
        throw new Error();
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setNoteCreateDisabled(false));
    }
  },
);

export const getNotes = createAsyncThunk(
  "lead/getNotes",
  async (
    body: { leadId?: number; filters?: Record<string, string> },
    { getState, dispatch },
  ) => {
    try {
      const { lead } = getState() as RootState;
      const res: IPaginatedResponse<INoteDetails[]> = await _API.get(
        lead.notes.upcomingLink || GET_NOTES(body.leadId || 1),
        NOTE_MAPPER,
        // Append filters only when upcoming link is not present (API will give next Link with filters)
        !lead.notes.upcomingLink
          ? {
              params: body.filters,
            }
          : undefined,
      );
      if (res?.results) {
        const noteList = transformLeadCreatedBy(res.results);
        dispatch(addNotes(noteList));
      }
      if (res?.next) dispatch(setNotesUpcomingLink(res.next));
      else dispatch(setHasMoreNotes(false));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting notes!");
      dispatch(setHasMoreNotes(false));
    }
  },
);

export const getLeadAssignees = createAsyncThunk(
  "lead/getLeadAssignees",
  async (body: { leadId?: number }, { getState, dispatch }) => {
    try {
      const { user: authUser } = getState() as RootState;
      const res: ILeadAssigneeUsers[] = await _API.get(
        GET_LEAD_ASSIGNEES,
        LEAD_ASSIGNEE_MAPPER,
        {
          params: {
            lead_id: body.leadId,
          },
        },
      );
      if (res?.length > 0) {
        const modifiedAssignees = transformLeadAssignees(res, authUser.id);
        dispatch(setLeadAssignees(modifiedAssignees));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting assignees!");
    }
  },
);

export const resetAllNotes = createAsyncThunk(
  "lead/resetNotes",
  async (_, { dispatch }) => {
    dispatch(resetNotes());
  },
);

//* Task Section Actions */
export const getTasks = createAsyncThunk(
  "task/getTasks",
  async (body: { leadId?: number }, { getState, dispatch }) => {
    try {
      const { lead } = getState() as RootState;
      const filtersApplied = lead.tasks.filters;
      const taskParams = {
        is_complete: filtersApplied.isCompleted || false,
        lead_id: body.leadId,
      };

      const res: IPaginatedResponse<ILeadTasks[]> = await _API.get(
        lead.tasks.upcomingLink || GET_TASKS,
        TASK_MAPPER,
        // Append filters only when upcoming link is not present (API will give next Link with filters)
        !lead.tasks.upcomingLink
          ? {
              params: taskParams,
            }
          : undefined,
      );

      if (res?.results) {
        const taskList = transformLeadCreatedBy(res.results);
        dispatch(addTasks(taskList));
      }
      if (res?.next) dispatch(setTasksUpcomingLink(res.next));
      else dispatch(setHasMoreTasks(false));

      const countNotes = res?.count;

      if (filtersApplied?.isCompleted) {
        dispatch(setCompletedTaskCount(countNotes || 0));
      } else {
        dispatch(setActiveTaskCount(countNotes || 0));
      }
    } catch (err: unknown) {
      let errorMessage = "Error getting tasks!";
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage);
      dispatch(setHasMoreTasks(false));
    }
  },
);

export const updateTask = createAsyncThunk(
  "task/updateTask",
  async (
    body: { leadId: number; taskId: number; data: ILeadTaskUpdatePayload },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.tasks.isWriteDisabled) return;
      dispatch(setTaskWriteDisabled(true));

      const res: ILeadTasks = await _API.patch(
        `${GET_TASKS}/${body.taskId}`,
        { is_complete: body.data.isCompleted },
        TASK_MAPPER,
      );

      if (res?.id) {
        const transformedTasks = transformLeadCreatedBy([res]);
        dispatch(replaceTask(transformedTasks[0]));
        dispatch(setActiveTaskCount((lead.tasks.activeTasksCount || 1) - 1));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error occured while updating task!",
      );
    } finally {
      dispatch(setTaskWriteDisabled(false));
    }
  },
);

export const resetAllTasks = createAsyncThunk(
  "task/resetAllTasks",
  async (_, { dispatch }) => {
    dispatch(resetTaskList());
  },
);

export const updateTaskFilters = createAsyncThunk(
  "task/updateTaskFilters",
  async (filters: Record<string, boolean>, { dispatch }) => {
    dispatch(setTaskFilters(filters));
  },
);

export const getTaskCategories = createAsyncThunk(
  "lead/getTaskCategories",
  async (_, { dispatch }) => {
    try {
      const res: ILeadTaskCategory[] = await _API.get(GET_TASK_CATEGORIES);
      if (res?.length > 0) {
        dispatch(setTaskCategories(res));
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error getting assignees!");
    }
  },
);

interface ITaskCreatePayload {
  lead: number;
  summary?: string;
  remind_at: string;
  task_category: number;
  assignee: number;
}

export const createNewTask = createAsyncThunk(
  "lead/createNewTask",
  async (
    body: { data: ITaskCreatePayload; leadId: number; callback?: () => void },
    { dispatch, getState },
  ) => {
    try {
      const { lead } = getState() as RootState;
      if (lead.tasks.isWriteDisabled) return;
      dispatch(setTaskWriteDisabled(true));

      const response: ILeadTasks = await _API.post(
        GET_TASKS,
        body.data,
        TASK_MAPPER,
      );

      if (response?.id) {
        // Increase active tasks count
        dispatch(setActiveTaskCount((lead.tasks.activeTasksCount || 0) + 1));
        // Push to active tasks list
        if (!lead.tasks.filters.isCompleted) {
          dispatch(unshiftTasks(response));
        }
        toastService.notify("success", "Task created successfully!");
        body.callback?.();
      } else {
        throw new Error();
      }
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Unexpected error occured!");
    } finally {
      dispatch(setTaskWriteDisabled(false));
    }
  },
);

//* Loan Documents */
export const getLoanDocuments = createAsyncThunk(
  "lead/getLoanDocuments",
  async (uuid: string, { dispatch }) => {
    dispatch(setDocumentsLoading(true));
    try {
      const res: LoanDocs = await _API.get(
        `${DOCUMENTS}/${uuid}`,
        DOCUMENTS_MAPPER,
      );
      dispatch(setDocuments(res));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Error updating application!",
      );
    }
    dispatch(setDocumentsLoading(false));
  },
);

export const uploadDocument = createAsyncThunk(
  "lead/uploadDoc",
  async (payload: any, { dispatch }) => {
    const { uuid, formData } = payload;
    try {
      dispatch(setUploadingDocument(true));
      await _API.post(
        UPDATE_DOCUMENT,
        formData,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toastService.notify("success", "Successfully uploaded file!");
      dispatch(getLoanDocuments(uuid));
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error uploading file!");
    } finally {
      dispatch(setUploadingDocument(false));
    }
  },
);

export const updateDocument = createAsyncThunk(
  "lead/updateDoc",
  async (payload: any, { dispatch }) => {
    const { documentId, form } = payload;
    try {
      dispatch(setUploadingDocument(true));
      await _API.patch(
        `${UPDATE_DOCUMENT}/${documentId}`,
        form,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      toastService.notify("success", "Successfully updated file!");
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify("error", errorMessage || "Error updating file!");
    } finally {
      dispatch(setUploadingDocument(false));
    }
  },
);

export const getMaskedInfo = createAsyncThunk(
  "lead/getUnMaskedInfo",
  async (
    payload: {
      fieldNames: MaskableFields;
    },
    { dispatch, getState },
  ) => {
    const { fieldNames } = payload;
    const { lead } = getState() as RootState;

    const unMaskingStatus = {
      email: !lead.unMasking.isEmailUnMasked,
      phone: !lead.unMasking.isPhoneUnMasked,
      alt_phone: !lead.unMasking.isAltPhoneUnMasked,
    };

    const fieldsToUnmask = fieldNames.filter(
      (fieldName) => unMaskingStatus[fieldName],
    );

    if (fieldsToUnmask.length === 0) return;

    dispatch(setUnMaskingLoadingVariable(fieldNames));

    try {
      const results: MaskedInfo[] = await Promise.all(
        fieldsToUnmask.map(async (fieldName) => {
          const response: MaskedInfo = await _API.get(
            UNMASKING(lead.leadData?.id as number, fieldName),
          );
          if (fieldName === "phone" && response.phone) {
            dispatch(setPhoneIsMasked(true));
            dispatch(setUnMaskingPhone(response.phone));
          } else if (fieldName === "email" && response.email) {
            dispatch(setEmailIsMasked(true));
            dispatch(setUnMaskingEmail(response.email));
          } else if (fieldName === "alt_phone" && response.altPhone) {
            dispatch(setAltPhoneIsMasked(true));
            dispatch(setUnMaskingAltPhone(response.altPhone));
          }
          return response;
        }),
      );

      return results;
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify(
        "error",
        errorMessage || "Failed to fetch information",
      );
    } finally {
      dispatch(setUnMaskingLoadingVariable([]));
    }
  },
);

export const copyLeadDetails = async (payload: { leadId: number }) => {
  try {
    const response: any = await _API.get(
      COPY_LEAD_DETAILS(payload.leadId),
      COPY_LEAD_DETAILS_MAPPER,
    );
    return response;
  } catch (error: unknown) {
    console.error("Error:", error);
    let errorMessage;
    if (error instanceof AxiosError) {
      errorMessage = error.response?.data?.message || error.message;
    }
    toastService.notify("error", errorMessage || "Failed to copy lead details");
  }
};

export const presignedDocLink = async (fileList: FileList, docKind: string) => {
  try {
    const body = {
      file_names: Array.from(fileList).map((file) => file.name),
      document_type: docKind,
    };

    const response: PresignedResponse[] = await _API.post(
      PRESIGNED_DOCUMENT_LINKS,
      body,
    );

    if (fileList.length !== response.length) {
      throw new Error(`Not All Files Are Uploaded!`);
    }

    const uploadPromises: Promise<void>[] = [];
    const uploadedFiles: { fileName: string; key: string }[] = [];

    response.forEach((fileData, index) => {
      try {
        const formData = new FormData();
        Object.keys(fileData.fields).forEach((key) => {
          formData.append(key, fileData.fields[key]);
        });
        const file = fileList.item(index);
        if (!file) {
          throw new Error(`File not uploaded.`);
        }
        formData.append("file", file);
        const uploadPromise = _API
          .post(
            fileData.url,
            formData,
            {},
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "",
              },
              baseURL: "",
            },
          )
          .then(() => {
            uploadedFiles.push({
              fileName: file.name,
              key: fileData.fields.key,
            });
          })
          .catch((error) => {
            console.error(
              `Error uploading file "${file.name}":`,
              error?.message,
            );
          });

        uploadPromises.push(uploadPromise);
      } catch (error) {
        console.error(`Error processing fileData at index ${index}:`, error);
      }
    });

    await Promise.all(uploadPromises);
    toastService.notify("success", "file uploaded successfully.");
    return uploadedFiles;
  } catch (err: any) {
    console.error("Error in presignedDocLink:", err);
    const errorMessage =
      err.response?.data?.details ||
      err.message ||
      "Document Upload Failed, Please Retry!";
    toastService.notify("error", errorMessage);
  }
};
export const getTemplates = createAsyncThunk(
  "leads/templates",
  async (payload: any, { dispatch }) => {
    dispatch(setTemplateLoading(true));
    try {
      const response: ITemplate[] = await _API.get(
        LEAD_TEMPLATES(payload.leadId),
        TEMPLATES_MAPPER,
      );
      dispatch(setTemplates(response));
    } catch (error: unknown) {
      console.error("Error:", error);
      let errorMessage;
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.message || error.message;
      }
      toastService.notify("error", errorMessage || "Failed to fetch templates");
    } finally {
      dispatch(setTemplateLoading(false));
    }
  },
);

export const forwardLead = async (payload: any) => {
  try {
    const response = await _API.post(`${Forward_Lead}`, payload);
    toastService.notify("success", "Lead forwarded successfully!");
    return response;
  } catch (error: unknown) {
    console.error("Error forwarding lead:", error);
    toastService.notify("error", "Error forwarding lead, please try again!");
    throw error;
  }
};
