//* Packages Imports */
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//* Components Imports */
import SkeletonCard from "@Core/SkeletonLoader/Card";
import CopyToClipboard from "@Components/utility/CopyToClipboard";
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";

//* Service Imports */
import { lead } from "@Slice/LeadSlice";
import { getCopyString } from "@Utils/common";
import {
  getAdmissionDetails,
  setLeadAdmissionDetails,
} from "@Actions/lead/DetailsForm";
import { MapBackendToFrontend } from "@Axios/mapping-framework";
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Data Imports */
import {
  ADMISSION_STATUS,
  DEGREES_LIST,
  EXAM_LIST,
  AREA_OF_STUDY,
} from "@Constants/leadDetailsData";

//* Assets Imports */
import AdmissionDetails from "@Assets/icons/AdmissionDetails.png";

//* Types Imports */
import { IAdmissionDetails } from "@Types/leads";
import { COPY_ADMISSION_DETAILS_MAPPER } from "@Mappers/lead";

const AcademicDetails = () => {
  const dispatch = useAppDispatch();
  const { isLoading, saveLoader, admissionDetails } =
    useAppSelector(lead).leadDetails.admissionDetailsObj;
  const { countries } = useAppSelector((state) => state.app);
  const { access } = useAppSelector((state) => state.user);

  const isDisabled = !access?.detail.admissionDetails.change;
  const { leadId } = useParams();

  const formInputData: Array<InputFieldsType> = [
    {
      type: "select",
      name: "admissionStatus",
      label: "Admission Status",
      required: true,
      options: ADMISSION_STATUS,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "areaOfStudy",
      label: "Area of Study",
      options: AREA_OF_STUDY,
      canSearch: true,
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      options: countries,
      labelKey: "name",
      valueKey: "id",
      required: true,
      disabled: isDisabled,
    },
    {
      type: "select",
      name: "degree",
      label: "Degree",
      options: DEGREES_LIST,
      disabled: isDisabled,
      required: true,
    },
    {
      type: "text",
      name: "university",
      label: "University",
      disabled: isDisabled,
      required: true,
    },
    {
      type: "date",
      name: "intake",
      label: "Intake",
      datePickerType: "single",
      disabled: isDisabled,
      required: true,
      disableMonthSelection: false,
      disableYearSelection: false,
      disablePreviousDates: true,
    },
    {
      type: "select",
      name: "exams",
      label: "Exam Given",
      options: EXAM_LIST,
      disabled: isDisabled,
    },
    {
      type: "number",
      name: "overallScore",
      label: "Overall Score",
      disabled: isDisabled,
      isFloat: true,
    },
  ];

  const inputFieldsData = useMemo(() => {
    return formInputData;
  }, [countries]);

  const handleFormSubmit = (formData: IAdmissionDetails) => {
    if (isNaN(Number(leadId))) return;

    dispatch(
      setLeadAdmissionDetails({
        leadId: Number(leadId),
        payload: formData,
      }),
    );
  };

  const admissionDetailsCopyContent = useMemo(() => {
    const modelMapper = new MapBackendToFrontend(COPY_ADMISSION_DETAILS_MAPPER);

    const formattedData: Record<string, string> =
      modelMapper.mapBackendToFrontend({
        admissionStatus: admissionDetails?.admissionStatus?.value,
        areaOfStudy: admissionDetails?.areaOfStudy?.value || "",
        country: admissionDetails?.country?.name || "",
        degree: admissionDetails?.degree?.value || "",
        university: admissionDetails?.university || "",
        intake: admissionDetails?.intake?.selectedDate || "",
        exams: admissionDetails?.exams?.value || "",
        overallScore: admissionDetails?.overallScore || "",
      });

    return getCopyString(formattedData);
  }, [admissionDetails]);

  useEffect(() => {
    if (leadId && countries.length)
      dispatch(getAdmissionDetails(Number(leadId)));
  }, [countries]);

  return (
    <div>
      <div className="flex relative gap-2 mb-7">
        <img src={AdmissionDetails} alt="Academic Details" />
        <h3>Admission Details</h3>
        <div className="absolute right-0 top-[6px]">
          <CopyToClipboard content={admissionDetailsCopyContent} />
        </div>
      </div>
      {isLoading ? (
        <SkeletonCard contentLines={9} gridCol={2} />
      ) : (
        <FormGenerator
          inputFieldsData={inputFieldsData}
          onSubmit={(formData) => handleFormSubmit(formData)}
          gridCol={2}
          defaultValues={admissionDetails || {}}
          hideSubmitButton={isDisabled}
          disableSubmitBtn={saveLoader}
        />
      )}
    </div>
  );
};

export default AcademicDetails;
