export const DISBURSAL_TABLE_DATA_MAPPER = {
  loan_application: "loanApplication",
  disbursed_amount: "disbursedAmount",
  loan_amount_approved: "loanAmountApproved",
  next_tranche_date: "nextTrancheDate",
  proof_of_disbursal: "proofOfDisbursal",
  disbursal_date: "disbursalDate",
  created_by: "createdBy",
  created_at: "createdAt",
  file_type: "fileType",
  review_id: "reviewId",
};

export const REVIEW_DOCUMENT = {
  instance_id: "disbursalId",
};

export const TIMELINE_LOGS_MAPPER = {
  created_at: "createdAt",
};

export const CONVERSION_LOGS_MAPPER = {
  loan_partner: "loanPartner",
  pf_paid_at: "pfPaidAt",
  loan_amount: "loanAmount",
  application_id: "applicationId",
  pf_status: "pfStatus",
  first_tranche_amount: "firstTrancheAmount",
  no_of_tranches: "noOfTranches",
  lender_name: "lenderName",
  loan_amount_approved: "loanAmountApproved",
  cancelled_conversion: "cancelledConversion",
  proof_of_pf_confirmation: "proofOfPfConfirmation",
  file_type: "fileType",
  review_id: "reviewId",
};

export const REPEATED_LEAD_LOGS_MAPPER = {
  lost_reason: "lostReason",
  assigned_at: "assignedAt",
};
