//* Components Imports */
import { InputFieldsType } from "@Core/FormGenerator";
import BaseForwardModal from "@Components/pages/leads/lead-details/CrossSale/BaseForwardModal";

//* Utils Imports */
import { generateIntakeOptions } from "@Core/Datepicker/utils";

//* Data imports */
import { COUNTRY_OPTIONS } from "@Constants/countryCode";

const scholarFormFields: Array<InputFieldsType> = [
  {
    type: "text",
    label: "Full Name",
    name: "fullName",
    disabled: true,
    required: true,
    placeholder: "Enter Full Name",
  },
  {
    type: "email",
    label: "Email ID",
    name: "email",
    disabled: true,
    required: true,
    placeholder: "Enter Email",
  },
  {
    type: "phone",
    label: "Mobile Number",
    name: "phone",
    disabled: true,
    required: true,
    countryCodeName: "countryCode",
  },
  {
    type: "select",
    label: "Country of Study",
    name: "country",
    options: COUNTRY_OPTIONS,
    required: true,
    placeholder: "Select Country",
  },
  {
    type: "radio",
    label: "Intake",
    name: "intake",
    required: true,
    options: generateIntakeOptions(),
  },
  {
    type: "radio",
    label: "Level of Study",
    name: "level_of_study",
    options: [
      { label: "Masters", value: "Masters" },
      { label: "Bachelors", value: "Bachelors" },
    ],
  },
  {
    type: "text",
    label: "Field of Study",
    name: "field_of_study",
    placeholder: "Enter field of study",
  },
  {
    type: "text",
    label: "Comments",
    name: "comments",
    placeholder: "Add any additional remark",
  },
];

const transformUniScholarsData = (values: any, leadData: any) => ({
  lead_id: leadData?.id,
  forward_to: "us",
  name: values?.fullName,
  phone: values?.countryCode?.dialCode + values?.phone,
  email: values?.email,
  country: values?.country?.value,
  intake: values?.intake,
  field_of_study: values?.field_of_study,
  level_of_study: values?.level_of_study,
  remark: values?.comments,
});

export const ForUniScholarsModal = ({ onClose }: { onClose: () => void }) => (
  <BaseForwardModal
    onClose={onClose}
    buttonText="Forward to UniScholars"
    formFields={scholarFormFields}
    transformFormData={transformUniScholarsData}
  />
);
