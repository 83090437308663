//* Packages Imports */
import { useRef, useState } from "react";

//* Components Imports */
import Select from "@Core/Select";
import Button from "@Core/Button";

//* Constants Imports */
import { LEAD_STATUS_LIST, STATUS_MAPPING } from "@Constants/bulkActions";
import { LEAD_STATUS } from "@Enums/dashboard";

//* Actions Import */
import { getLostReasons } from "@Actions/app";

//* Hooks Import */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Utils Imports */
import { toastService } from "@Utils/toast";

//* Types Imports */
import { LeadStatusInfoTypes } from "@Types/dashboard";
import { StatusKeyEnum, USER_TYPE } from "@Enums/common";

//* Assets Imports */
import RightArrow from "@Assets/icons/arrow-right.png";
import Warning from "@Assets/icons/warnig-triangle-yellow.png";
import Loader from "@Assets/images/loader.gif";

type ChooseStatusModalPropsTypes = {
  updateLeadsStatus: (leadData: LeadStatusInfoTypes) => void;
  handleCancel: () => void;
  isLoading: boolean;
};

const ChooseStatusModal = ({
  updateLeadsStatus,
  handleCancel,
  isLoading,
}: ChooseStatusModalPropsTypes) => {
  const selectedLostReasonRef = useRef<string | null>();

  const dispatch = useAppDispatch();
  const { lostReasons } = useAppSelector((state) => state.app);
  const { role } = useAppSelector((state) => state.user);

  const [loadingReason, setLoadingReason] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<{
    value: StatusKeyEnum;
    label: LEAD_STATUS;
  } | null>(null);

  const handleStatusChange = () => {
    if (!selectedStatus)
      return toastService.notify("error", "Please Select Current Leads Status");

    updateLeadsStatus({
      currentStatus: selectedStatus?.value,
      updatedStatus: STATUS_MAPPING[selectedStatus.label].value,
      lostReason: selectedLostReasonRef.current || undefined,
    });
  };

  const fetchLostReason = () => {
    setLoadingReason(true);
    try {
      if (!lostReasons.length) dispatch(getLostReasons());
    } catch (error) {
      console.error(error);
      toastService.notify(
        "error",
        "Failed to fetch lost reason. Please try again.",
      );
    } finally {
      setLoadingReason(false);
    }
  };

  const UPDATED_LEAD_STATUS_LIST = LEAD_STATUS_LIST.filter((status) => {
    if (role === USER_TYPE.LQT_HEAD) {
      return status.value === StatusKeyEnum.PRE_QUALIFIED;
    }
    return true;
  });

  return (
    <div className="w-[600px]">
      <div className="w-full">
        <div className="flex w-full justify-between mt-4">
          <div className="mb-4 w-[45%]">
            <span className="text-sm mb-2 font-normal block">
              Choose lead status
            </span>
            <Select
              labelKey="label"
              valueKey="value"
              placeholder="Lead status"
              options={UPDATED_LEAD_STATUS_LIST}
              setValue={(selectedVal: {
                value: StatusKeyEnum;
                label: LEAD_STATUS;
              }) => setSelectedStatus(selectedVal)}
            />
          </div>
          <div className="w-[10%] flex items-center justify-center mt-2">
            <img src={RightArrow} alt="right-arrow" />
          </div>
          <div className="mb-4 w-[45%]">
            <p className="text-sm mb-2 font-normal">Updated lead Status</p>
            <p className="border w-full rounded h-8 flex items-center pl-4 py-1 border-american-silver text-tag-gray">
              {selectedStatus
                ? STATUS_MAPPING[selectedStatus.label].label
                : "Updated status"}
            </p>
          </div>
        </div>
        {selectedStatus?.label === LEAD_STATUS.CREATED && (
          <div className="flex justify-end mt-4">
            <div className="mb-4 w-[45%]">
              <p className="text-sm mb-2 font-normal">Choose lost reason</p>
              <Select
                labelKey="name"
                valueKey="name"
                placeholder="Select reason"
                fetchOptions={fetchLostReason}
                optionsLoading={loadingReason}
                options={lostReasons}
                setValue={(selectedVal: { name: string }) => {
                  selectedLostReasonRef.current = selectedVal.name;
                }}
              />
            </div>
          </div>
        )}
      </div>

      {selectedStatus && (
        <div className="flex items-center gap-3 bg-yellow-caution-bg p-2 my-3 rounded-lg border border-yellow-caution-border">
          <img src={Warning} alt="warning" />
          <p className="text-xs font-normal text-gray block">
            leads will be moved from <strong>{selectedStatus.label} </strong>
            to <strong>{STATUS_MAPPING[selectedStatus.label].label} </strong>
            status. This action is irreversible, proceed with caution!
          </p>
        </div>
      )}

      <div className="flex float-end mt-6 gap-3 w-[332px] h-[34px]">
        <Button
          variant="outline"
          classes="w-[160px] h-[34px] flex justify-center items-center"
          font="sm"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {isLoading ? (
          <img src={Loader} alt="loading..." className="ml-8" />
        ) : (
          <Button
            variant="solid"
            classes="w-[160px] h-[34px] flex justify-center items-center"
            disabled={!selectedStatus}
            font="sm"
            onClick={handleStatusChange}
          >
            Change Status
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChooseStatusModal;
