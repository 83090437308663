//* Components Import */
import Checkbox from "@Core/Checkbox";
import { STATUS_COLOR } from "@Core/StatusBadge/constants";

//* Types Import */
import { ILeadsListingItem } from "@Types/dashboard";

interface LeadCardProps {
  data: ILeadsListingItem;
  onClick: (val: string | number, checked: boolean) => void;
  isCheckedState: boolean;
}

export default function LeadCard({
  data,
  onClick,
  isCheckedState,
}: LeadCardProps) {
  const statusColorClass = `${data.status && STATUS_COLOR[data.status]?.color} w-2 h-2 rounded-sm`;
  const statusLabel = data.status && STATUS_COLOR[data.status].label;
  return (
    <div className="bg-white border border-gray-border rounded-lg h-[54px] grid items-center p-4 grid-cols-5 mb-2">
      <div className="flex items-center gap-3">
        <div>
          <Checkbox
            name={data.name}
            label={""}
            value={data.id}
            checked={isCheckedState}
            onChange={onClick}
          />
        </div>

        <span className="text-sm">{data.id}</span>
      </div>

      {data.status && (
        <span className="text-sm flex items-center gap-3">
          <span className={statusColorClass} />
          <span>{statusLabel}</span>
        </span>
      )}

      <span className="text-sm">{data.name}</span>

      <span className="text-sm">{data.agent}</span>

      <span className="text-sm">{data.assignedOn}</span>
    </div>
  );
}
