//* Packages Imports */
import { useState, useMemo, useEffect, useRef } from "react";

//* Components imports */
import FormGenerator, { InputFieldsType } from "@Core/FormGenerator";
import Button from "@Core/Button";

//* Utils */
import { getMaskedInfo, forwardLead } from "@Actions/lead";
import { useAppSelector, useAppDispatch } from "@Hooks/redux-hooks";
import { toastService } from "@Utils/toast";

interface BaseForwardModalProps {
  onClose: () => void;
  buttonText: string;
  formFields: Array<InputFieldsType>;
  transformFormData: (values: any, leadData: any) => any;
}

const BaseForwardModal = ({
  onClose,
  buttonText,
  formFields,
  transformFormData,
}: BaseForwardModalProps) => {
  const [forwardLeadLoading, setForwardLeadLoading] = useState(false);
  const unmaskedPhoneRef = useRef<string | null>(null);
  const unmaskedEmailRef = useRef<string | null>(null);

  const leadData = useAppSelector((state) => state.lead.leadData);
  const dispatch = useAppDispatch();

  const isPhoneUnmasked = useAppSelector(
    (state) => state.lead.unMasking.isPhoneUnMasked,
  );

  const isEmailUnmasked = useAppSelector(
    (state) => state.lead.unMasking.isEmailUnMasked,
  );

  useEffect(() => {
    const fetchUnmaskedInfo = async () => {
      if (isPhoneUnmasked && isEmailUnmasked) {
        unmaskedPhoneRef.current = leadData?.phone || null;
        unmaskedEmailRef.current = leadData?.email || null;
        return;
      }
      try {
        const response: any = await dispatch(
          getMaskedInfo({ fieldNames: ["phone", "email"] }),
        );
        unmaskedPhoneRef.current =
          response?.payload?.[0]?.phone ?? leadData?.phone;
        unmaskedEmailRef.current =
          response?.payload?.[0]?.email ?? leadData?.email;
      } catch (error) {
        console.error("Error fetching unmasked info:", error);
        toastService.notify(
          "error",
          "Error fetching unmasked info, please try again!",
        );
      }
    };
    fetchUnmaskedInfo();
  }, []);

  const defaultValues = useMemo(() => {
    const phoneNumber = (unmaskedPhoneRef.current || leadData?.phone || "")
      .replace(/^\+91/, "")
      .trim();

    return {
      fullName: leadData?.name || "",
      email: unmaskedEmailRef.current || leadData?.email || "",
      phone: phoneNumber,
      countryCode: {
        name: "India",
        dialCode: "+91",
        code: "IN",
      },
    };
  }, [leadData]);

  const handleSubmit = async (values: any) => {
    const payload = transformFormData(values, leadData);

    try {
      setForwardLeadLoading(true);
      await forwardLead(payload);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setForwardLeadLoading(false);
    }
  };

  return (
    <div className="w-[800px] p-6">
      <div>
        <span className="text-black text-lg">Fill Lead Details</span>
      </div>
      <div className="mt-5">
        <FormGenerator
          inputFieldsData={formFields}
          onSubmit={handleSubmit}
          gridCol={2}
          defaultValues={defaultValues}
          hideSubmitButton
          customFooter={
            <div className="flex items-center justify-end gap-3 pt-5">
              <Button
                classes="w-40"
                font="sm"
                variant="error"
                onClick={onClose}
                type="button"
              >
                Cancel
              </Button>

              <Button
                classes="w-56"
                font="sm"
                type="submit"
                disabled={forwardLeadLoading}
              >
                {forwardLeadLoading ? "Forwarding..." : buttonText}
              </Button>
            </div>
          }
          labelPosition="top"
        />
      </div>
    </div>
  );
};

export default BaseForwardModal;
