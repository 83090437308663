//* Packages Import */
import { useEffect, useRef, useState } from "react";

//* Component Imports */
import Button from "@Core/Button";

//* Constants Imports*/
import { BULK_ACTION_CONFIG, TEMPLATES_CSV } from "@Constants/bulkActions";

//* Types Imports */
import { BULK_ACTION_TYPE } from "@Enums/dashboard";

//* Assests Imports */
import DocumentIcon from "@Assets/icons/doc-upload.png";
import Warnig from "@Assets/icons/warnig-triangle-yellow.png";
import Loader from "@Assets/images/loader.gif";

interface IBulkActionCSVUploader {
  actionType: BULK_ACTION_TYPE;
  handleNextSteps: (csvFile: FileList) => void;
  resetFile?: boolean;
  setResetFile?: (value: boolean) => void;
  isFileUploading: boolean;
}

const BulkActionCSVUploader = ({
  actionType = BULK_ACTION_TYPE.BULK_REASSIGN,
  handleNextSteps,
  setResetFile,
  resetFile,
  isFileUploading,
}: IBulkActionCSVUploader) => {
  const { title, description, showWarning, warningMessage, primaryButtonCTA } =
    BULK_ACTION_CONFIG[actionType];

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files || null;
    if (uploadedFile && uploadedFile.length > 0) {
      setSelectedFile(uploadedFile);
    }
  };

  const handleReplaceCSV = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (resetFile) {
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setResetFile?.(false);
    }
  }, [resetFile, setResetFile]);

  return (
    <>
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <span className="text-sm mb-2 font-normal">{description}</span>
      <label
        className={`my-4 min-w-[422px] h-[120px] flex flex-col items-center justify-center border  p-6 rounded-md cursor-pointer ${
          selectedFile ? "bg-mint-green border-light-gray" : "border-dashed"
        }`}
      >
        <input
          type="file"
          accept=".csv"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        <img src={DocumentIcon} alt="Upload" className="w-8 h-8" />
        <span className="mt-2 text-gray text-sm">
          {selectedFile ? selectedFile[0]?.name : "Click to upload file"}
        </span>
      </label>

      {showWarning && (
        <div className="flex items-center gap-3 bg-yellow-caution-bg p-2 my-3 rounded-lg">
          <img src={Warnig} alt="warning" />
          <span className="text-xs font-normal text-gray">
            {warningMessage}
          </span>
        </div>
      )}

      <a href={TEMPLATES_CSV} className="text-link underline text-sm ">
        Sample template.csv
      </a>

      <div className="flex float-end mt-10 gap-3 w-[332px] h-[34px]">
        <Button
          variant="outline"
          disabled={!selectedFile}
          classes="w-[160px] h-[34px] flex justify-center items-center"
          font="sm"
          onClick={handleReplaceCSV}
        >
          Replace CSV
        </Button>
        {isFileUploading ? (
          <img src={Loader} alt="loading..." className="ml-8" />
        ) : (
          <Button
            variant="solid"
            classes="w-[160px] h-[34px] flex justify-center items-center"
            disabled={!selectedFile}
            font="sm"
            onClick={() => selectedFile && handleNextSteps(selectedFile)}
          >
            {primaryButtonCTA}
          </Button>
        )}
      </div>
    </>
  );
};

export default BulkActionCSVUploader;
