export enum BULK_ACTION_TYPE {
  BULK_STATUS = "BulkStatus",
  BULK_REASSIGN = "BulkReassign",
}

export enum LEAD_STATUS {
  PREQUALIFIED = "Prequalified",
  CREATED = "Created",
  OPPORTUNITY = "Opportunity",
}

export enum BULK_COMPLETION_STATUS {
  SUCCESS = "Success",
  PARTIALLY_CONVERTED = "Partially Converted",
  ERROR = "Error",
}
