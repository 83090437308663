//* Packages Import */
import { useRef, useState } from "react";

//* Components Imports */
import BulkActionCSVUploader from "@Components/pages/BulkAssignment/ReassignModal/BulkActionCSVUploader";
import ChooseStatusModal from "@Components/pages/BulkStatusChnage/ChooseStatusModal";
import Modal from "@Components/utility/Modal";

//* Actions Import */
import { presignedDocLink } from "@Actions/lead";
import { bulkStatusChangeApi } from "@Actions/dashboard";

//* Utils Import */
import { toastService } from "@Utils/toast";

//* Types & Enums Import */
import { BULK_ACTION_TYPE } from "@Enums/dashboard";
import { LeadStatusInfoTypes } from "@Types/dashboard";
import { PRESIGNED_DOCUMENT_KINDS, StatusKeyEnum } from "@Enums/common";

const BulkStatusChange = () => {
  const csvDocPathRef = useRef<{ fileName: string; key: string }[] | undefined>(
    undefined,
  );
  const [bulkStatusModal, setBulkStatusModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentStatusChangeModal, setCurrentStatusChangeModal] = useState<
    string | null
  >(null);
  const [resetFile, setResetFile] = useState(false);

  const handleNextSteps = async (csvFile: FileList) => {
    setLoading(true);
    if (csvFile?.length) {
      csvDocPathRef.current = await presignedDocLink(
        csvFile,
        PRESIGNED_DOCUMENT_KINDS.BULK_ACTION,
      );
      if (csvDocPathRef.current) setCurrentStatusChangeModal("chooseStatus");
    }

    setBulkStatusModal(true);
    setLoading(false);
  };

  const handleCancel = () => {
    setCurrentStatusChangeModal(null);
    setBulkStatusModal(false);
    setResetFile(true);
  };

  const updateLeadStatus = async (leadStatusInfo: LeadStatusInfoTypes) => {
    setLoading(true);
    if (
      leadStatusInfo.updatedStatus === StatusKeyEnum.LOST &&
      !leadStatusInfo.lostReason
    ) {
      setLoading(false);
      return toastService.notify("error", "Please select a lost reason");
    }

    try {
      if (!csvDocPathRef.current)
        return toastService.notify("error", "Please upload a file");
      const statusChangeData = {
        s3FilePath: csvDocPathRef.current[0].key,
        currentStatus: leadStatusInfo.currentStatus,
        updatedStatus: leadStatusInfo.updatedStatus,
        lostReason: leadStatusInfo.lostReason || undefined,
      };

      await bulkStatusChangeApi(statusChangeData);
      handleCancel();
      setCurrentStatusChangeModal(null);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const bulkStatusModalSteps: Record<string, JSX.Element> = {
    chooseStatus: (
      <ChooseStatusModal
        updateLeadsStatus={updateLeadStatus}
        handleCancel={handleCancel}
        isLoading={loading}
      />
    ),
  };

  return (
    <div className="w-[550px] h-[390px] mt-4 ml-4 bg-white p-6 rounded-lg border border-background-gray">
      <BulkActionCSVUploader
        actionType={BULK_ACTION_TYPE.BULK_STATUS}
        handleNextSteps={handleNextSteps}
        resetFile={resetFile}
        setResetFile={setResetFile}
        isFileUploading={loading}
      />

      {bulkStatusModal && currentStatusChangeModal !== null && (
        <Modal open={bulkStatusModal} onClose={handleCancel}>
          <h2 className="text-xl font-semibold">Bulk Status Change</h2>
          {bulkStatusModalSteps[currentStatusChangeModal]}
        </Modal>
      )}
    </div>
  );
};

export default BulkStatusChange;
