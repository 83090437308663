//* Packages */
import { Outlet } from "react-router-dom";

//* Components */
import Header from "@Components/Header";

export default function DashboardLayout() {
  return (
    <div className="relative">
      <Header />
      <Outlet />
    </div>
  );
}
