//* Utils Imports //
import { formatDateToString } from "@Utils/dateUtils";
import { formatCurrency } from "@Utils/common";

//* Types Imports //
import { ILogsColumnKeyMap, ILogsResponseData } from "@Types/timeline";

export function transformIntoTableRenderData<T>(
  apiResponseData: unknown,
  logsKeyColumnMap: ILogsColumnKeyMap[],
): ILogsResponseData<T>[] {
  const responses = Array.isArray(apiResponseData)
    ? apiResponseData
    : [apiResponseData];

  return responses.map((response) => {
    const logsTransformData = {} as ILogsResponseData<T>;
    logsKeyColumnMap.forEach((columnConfig) => {
      const { objectKeyFromApi, cellType } = columnConfig;
      const value = response[objectKeyFromApi as keyof T];
      let displayValue: any = value;
      switch (objectKeyFromApi) {
        case "pfPaidAt":
          if (typeof value === "string") {
            displayValue = formatDateToString(value);
          }
          break;
        case "loanAmount":
        case "firstTrancheAmount":
        case "disbursedAmount":
          if (typeof value === "number" && value > 0) {
            displayValue = `${formatCurrency(value, true)} ${response["currency"] || ""}`;
          }
          break;
        case "roi":
          if (typeof value === "number") {
            displayValue = `${value}%`;
          }
          break;

        case "lead":
          if (typeof value === "object") {
            displayValue = `${value.name} (${value.id})`;
          }
          break;
        case "cancelledConversion":
          displayValue = value ? "CANCELLED" : "ACTIVE";
          break;
        case "proofOfDisbursal":
        case "proofOfPfConfirmation":
          if (typeof value === "object") {
            displayValue = value?.url;
          }
          break;

        default:
          displayValue = value;
      }

      logsTransformData[objectKeyFromApi as keyof T] = {
        value,
        displayValue,
        type: cellType,
      };
    });

    return logsTransformData;
  });
}
