import { CELL_TYPE } from "@Enums/common";
import { ILogsColumnKeyMap } from "@Types/timeline";

export const CONVERSION_TABLE_HEADERS: ILogsColumnKeyMap[] = [
  {
    label: "#",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "id",
    isActive: true,
  },
  {
    label: "PF Paid Date",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "pfPaidAt",
    isActive: true,
  },
  {
    label: "PF Status",
    cellType: CELL_TYPE.BADGE,
    objectKeyFromApi: "pfStatus",
    isActive: true,
  },
  {
    label: "Conv Status",
    cellType: CELL_TYPE.BADGE,
    objectKeyFromApi: "cancelledConversion",
    isActive: true,
  },
  {
    label: "Proof Of PF Confirmation",
    cellType: CELL_TYPE.DOC,
    objectKeyFromApi: "proofOfPfConfirmation",
    isActive: true,
  },
  {
    label: "Name",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "name",
    isActive: false,
  },
  {
    label: "Email",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "email",
    isActive: false,
  },
  {
    label: "Loan Partner",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "loanPartner",
    isActive: true,
  },
  {
    label: "Application Id",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "applicationId",
    isActive: true,
  },
  {
    label: "Loan Amount",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "loanAmount",
    isActive: true,
  },
  {
    label: "ROI",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "roi",
    isActive: true,
  },
  {
    label: "Tranches",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "noOfTranches",
    isActive: true,
  },
  {
    label: "1st Tranche",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "firstTrancheAmount",
    isActive: true,
  },
  {
    label: "Bank Agent",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "lenderName",
    isActive: true,
  },
  {
    label: "Currrency",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "currency",
    isActive: false,
  },
];

export const DISBURSAL_TABLE_HEADERS: ILogsColumnKeyMap[] = [
  {
    label: "#",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "id",
    isActive: true,
  },
  {
    label: "Disbursed Amount",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "disbursedAmount",
    isActive: true,
  },
  {
    label: "Disbursed Date",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "disbursalDate",
    isActive: true,
  },
  {
    label: "Proof of Disbursement",
    cellType: CELL_TYPE.DOC,
    objectKeyFromApi: "proofOfDisbursal",
    isActive: true,
  },
  {
    label: "Created At",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "createdAt",
    isActive: true,
  },
  {
    label: "Created By",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "createdBy",
    isActive: true,
  },
  {
    label: "Edit",
    cellType: CELL_TYPE.EDIT,
    isActive: true,
  },
  {
    label: "Next Tranche Date",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "nextTrancheDate",
    isActive: false,
  },
  {
    label: "Currency",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "currency",
    isActive: false,
  },
  {
    label: "Loan Amount Approved",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "loanAmountApproved",
    isActive: false,
  },
  {
    label: "Roi",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "roi",
    isActive: false,
  },
  {
    label: "Lead Id",
    cellType: CELL_TYPE.LEAD,
    objectKeyFromApi: "lead",
    isActive: false,
  },
];

export const REPEATED_LEADS_TABLE_HEADERS: ILogsColumnKeyMap[] = [
  {
    label: "#",
    cellType: CELL_TYPE.LINK,
    objectKeyFromApi: "id",
    isActive: true,
  },
  {
    label: "Name",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "name",
    isActive: true,
  },
  {
    label: "Email",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "email",
    isActive: true,
  },
  {
    label: "Phone",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "phone",
    isActive: true,
  },
  {
    label: "Status",
    cellType: CELL_TYPE.BADGE,
    objectKeyFromApi: "status",
    isActive: true,
  },
  {
    label: "Agent",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "agent",
    isActive: true,
  },
  {
    label: "Created At",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "assignedAt",
    isActive: true,
  },
  {
    label: "Lost Reason",
    cellType: CELL_TYPE.TEXT,
    objectKeyFromApi: "lostReason",
    isActive: true,
  },
];
