//* Types Import *//
import { REVIEW_DOC_STATUS } from "@Enums/common";
import { IReviewDocStatusConfig } from "@Types/timeline";

//* Assets Import //
import Reject from "@Assets/icons/remove-red-fill.png";
import Warning from "@Assets/icons/warning.png";
import Approved from "@Assets/icons/green-check-fill-black.png";

export const STATUS_TEXT_MAP = {
  READY: {
    title: "Ready for Assignment",
    class: "text-success",
    subtext: "This lead has all the mandatory fields for Lender Assignment",
  },
  NOT_READY: {
    title: "Not Ready for Assignment",
    class: "text-error",
    subtext: "Lead is missing one or more fields in  Admission Details",
  },
  PARTIALLY_READY: {
    title: "Partial Documents",
    class: "text-warning",
    subtext: "Mandatory documents uploaded, but some are still missing.",
  },
};
export const DOC_NAME: Record<string, string> = {
  aadharFront: "Aaadhar Card (Front)",
  aadharBack: "Aaadhar Card (Back)",
  motherAadharFront: "Mother Aadhar Card (Front)",
  PAN: "PAN Card",
  passport: "Passport",
  passportPhoto: "Passport Size Photo",
  motherPAN: "Mother PAN Card",
  fatherPAN: "Father PAN Card",
  tenthMarksheet: "10th Marksheet",
  twelfthMarksheet: "12th Marksheet",
  graduationMarksheet: "Graduation Marksheet",
  postGraduationMarksheet: "Post Graduation Marksheet",
};

export const PAGE_SIZE_OPTIONS = [
  { label: "10" },
  { label: "20" },
  { label: "50" },
];

export const ENCODED_WHATSAPP_MESSAGES = {
  DOCUMENT_UPLOAD: (
    name: string,
  ) => `URL encoding of "Hi ${name}, Greetings from UniCreds. 
To proceed with your loan enquiry, we will need a set of documents from you. 
You can upload documents into your student dashboard through link given below under KYC, 
Academic and Financial Details section https://unicreds.com/login`,
};

export const DOC_STATUS: {
  [key in REVIEW_DOC_STATUS]: IReviewDocStatusConfig;
} = {
  [REVIEW_DOC_STATUS.PENDING]: {
    icon: Warning,
    color: "text-created",
  },
  [REVIEW_DOC_STATUS.REJECTED]: {
    icon: Reject,
    color: "text-error",
  },
  [REVIEW_DOC_STATUS.APPROVED]: {
    icon: Approved,
    color: "text-success",
  },
};
