//* Components Imports */
import { InputFieldsType } from "@Core/FormGenerator";
import BaseForwardModal from "@Components/pages/leads/lead-details/CrossSale/BaseForwardModal";

//* Utils Imports */
import { getParamDateString } from "@Utils/dateUtils";

//* Data imports */
import { NATIONALITY_OPTIONS } from "@Constants/nationalityData";

const formFields: Array<InputFieldsType> = [
  {
    type: "text",
    label: "Full Name",
    name: "fullName",
    required: true,
    disabled: true,
    placeholder: "Enter Full Name",
  },
  {
    type: "email",
    label: "Email ID",
    name: "email",
    required: true,
    disabled: true,
    placeholder: "Enter Email",
  },
  {
    type: "phone",
    label: "Mobile Number",
    name: "phone",
    required: true,
    disabled: true,
    countryCodeName: "countryCode",
  },
  {
    type: "text",
    label: "City",
    name: "city",
    required: true,
    placeholder: "Add City",
  },
  {
    type: "text",
    label: "University",
    name: "university",
    required: true,
    placeholder: "Add University",
  },
  {
    type: "date",
    name: "moveIn",
    label: "Move In",
    required: true,
    datePickerType: "single",
    placeholder: "Select Move in Date",
  },
  {
    type: "number",
    label: "Budget",
    name: "budget",
    placeholder: "Add Budget",
  },
  {
    type: "text",
    label: "Room Type",
    name: "roomType",
    placeholder: "Enter room type",
  },
  {
    type: "select",
    label: "Nationality",
    name: "nationality",
    options: NATIONALITY_OPTIONS,
    canSearch: true,
  },
  {
    type: "text",
    label: "Comments",
    name: "comments",
    placeholder: "Add any additional remark",
  },
];

const transformUniAccoData = (values: any, leadData: any) => ({
  lead_id: leadData?.id,
  forward_to: "ua",
  fullname: values?.fullName,
  email: values?.email,
  remark: values?.comments,
  city: values?.city || "",
  university: values?.university || "",
  budget: values?.budget || "",
  lead_room_type: values?.roomType || "",
  movein: getParamDateString(values?.moveIn.selectedDate) || "",
  country: values?.country?.name,
  country_code: values?.countryCode?.dialCode,
  phone: values?.countryCode?.dialCode + values?.phone,
  nationality: values?.nationality?.value || "",
});

export const ForUniAccoModal = ({ onClose }: { onClose: () => void }) => (
  <BaseForwardModal
    onClose={onClose}
    buttonText="Forward to UniAcco"
    formFields={formFields}
    transformFormData={transformUniAccoData}
  />
);
