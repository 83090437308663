import { IFilterConfig } from "@Types/common";
import {
  AGENT_LEAD_STATUS,
  LENDER_LEAD_STATUS,
} from "@Constants/leadDetailsData";

export const DEFAULT_SORT_OPTIONS = [
  {
    label: "Latest",
    value: "-created_at",
  },
  { label: "Oldest", value: "created_at" },
];

export const TASKS_FILTER_CONFIG: Record<string, IFilterConfig> = {
  daterange: {
    type: "daterange",
    label: "Due Date Range",
    queryKey: "date_range",
  },
  agents: {
    type: "list",
    label: "Agent",
    queryKey: "filter_agent_ids",
    variant: "multiSelect",
    optionsKey: "team",
    labelKey: "name",
    valueKey: "id",
    canSearch: true,
  },
  leadStatus: {
    type: "list",
    label: "Lead Status",
    queryKey: "lead_status",
    variant: "multiSelect",
    options: AGENT_LEAD_STATUS,
  },
  taskStatus: {
    type: "list",
    label: "Task Status",
    variant: "singleSelect",
    queryKey: "task_status",
    options: [
      { label: "All", value: "all" },
      { label: "Active", value: "active" },
      { label: "Completed", value: "completed" },
      { label: "Overdue", value: "overdue" },
    ],
  },
  assignedTo: {
    type: "list",
    label: "Assigned to",
    variant: "singleSelect",
    queryKey: "assigned_to",
    options: [
      { label: "Assigned to me", value: "me" },
      { label: "Assigned to others", value: "others" },
    ],
  },
  applicationStatus: {
    type: "list",
    label: "Application Status",
    variant: "multiSelect",
    queryKey: "app_status",
    options: LENDER_LEAD_STATUS,
  },
};

export const LEADS_FILTER_CONFIG: Record<string, IFilterConfig> = {
  daterange: {
    type: "daterange",
    label: "Date Range",
    queryKey: "date_range",
  },
  leadType: {
    type: "list",
    label: "Lead Type",
    variant: "multiSelect",
    queryKey: "lead",
    options: [
      { label: "Only Repeated", value: "REPEATED" },
      { label: "Hide Repeated", value: "NOT REPEATED" },
      { label: "Only Renewed", value: "RENEWED" },
      { label: "Flagged", value: "FLAGGED" },
      { label: "LQT Not Attempted", value: "LQT_NOT_ATTEMPTED" },
    ],
  },
  admissionStatus: {
    type: "list",
    label: "Admission Status",
    variant: "singleSelect",
    queryKey: "admission",
    options: [
      { label: "Not Applied", value: "NOT APPLIED" },
      { label: "Applied", value: "APPLIED" },
      { label: "Conditional Offer Letter", value: "CONDITIONAL OFFER LETTER" },
      {
        label: "Unconditional Offer Letter",
        value: "UNCONDITIONAL OFFER LETTER",
      },
      { label: "Unknown", value: "UNKNOWN" },
    ],
  },
  loanAmount: {
    type: "list",
    label: "Loan Amount",
    queryKey: "loan_range",
    options: [
      {
        label: "0 to 5 Lakh",
        value: "0:500000",
      },
      {
        label: "5 to 10 Lakh",
        value: "500000:1000000",
      },
      {
        label: "10 to 15 Lakh",
        value: "1000000:1500000",
      },
      {
        label: "15 to 20 Lakh",
        value: "1500000:2000000",
      },
      {
        label: "20+",
        value: "2000000:0",
      },
    ],
  },
  lostReasons: {
    type: "list",
    label: "Lost Reasons",
    queryKey: "lost_reason",
    optionsKey: "lostReasons",
    labelKey: "name",
    valueKey: "slug",
    variant: "multiSelect",
    canSearch: true,
  },
  tags: {
    type: "list",
    label: "Tags",
    queryKey: "tag",
    labelKey: "name",
    valueKey: "code",
    optionsKey: "tags",
    variant: "multiSelect",
    canSearch: true,
  },
  utmSource: {
    type: "list",
    label: "UTM Source",
    queryKey: "utm_source",
    optionsKey: "utmSources",
    labelKey: "label",
    valueKey: "value",
    variant: "multiSelect",
    canSearch: true,
  },
  utmMedium: {
    type: "list",
    label: "UTM Medium",
    queryKey: "utm_medium",
    optionsKey: "utmMediums",
    labelKey: "label",
    valueKey: "value",
    variant: "multiSelect",
    canSearch: true,
  },
  agents: {
    type: "list",
    label: "Agent",
    queryKey: "user_id",
    variant: "multiSelect",
    optionsKey: "team",
    labelKey: "name",
    valueKey: "id",
    canSearch: true,
  },
};

export const TASKS_FILTER_KEYS: Record<string, string[]> = {
  agent: ["daterange", "leadStatus", "agents", "taskStatus", "assignedTo"],
  lender: ["daterange", "applicationStatus", "taskStatus"],
};

export const LEADS_FILTER_KEYS = [
  "daterange",
  "leadType",
  "agents",
  "admissionStatus",
  "loanAmount",
  "lostReasons",
  "tags",
  "utmSource",
  "utmMedium",
];
