//* Package Imports */
import { Route, Routes } from "react-router-dom";

//* Guarding */
import Public from "@Router/Public";
import Protected from "@Router/Protected";

//* Layouts */
import DetailsLayout from "@Layouts/details";
import DashboardLayout from "@Layouts/dashboard";
import HomeLayout from "@Layouts/home";

//* Pages */
import Login from "@Pages/login";
import NotFound from "@Pages/404";
import NewLead from "@Pages/new-lead";
import Home from "@Pages/home";
import LeadDetails from "@Pages/leads/lead-details";
import Authorize from "@Pages/authorize/authorize";
import BulkAssignment from "@Pages/dashboard/bulk-assignment";
import BulkStatusChange from "@Pages/dashboard/bulk-status-change";

//* Service Imports */
import { getCookiesValue } from "@Utils/storage";

export default function AppRoutes() {
  const isAuth = getCookiesValue("token");

  return (
    <Routes>
      <Route element={<Protected isAuthenticated={isAuth} />}>
        <Route element={<DetailsLayout />}>
          <Route path="/leads/:leadId" element={<LeadDetails />} />
          <Route path="/new-lead" element={<NewLead />} />
        </Route>

        <Route element={<HomeLayout />}>
          <Route element={<Home />} path="/" />
        </Route>

        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard/bulk-assignment"
            element={<BulkAssignment />}
          />
          <Route
            path="/dashboard/bulk-status-change"
            element={<BulkStatusChange />}
          />
        </Route>
      </Route>

      <Route element={<Public isAuthenticated={isAuth} />}>
        <Route element={<Login />} path="/login/"></Route>
        <Route element={<Authorize />} path="/authorize"></Route>
      </Route>

      <Route element={<NotFound />} path="/*"></Route>
    </Routes>
  );
}
