export const LEAD_DETAILS_MAPPER = {
  alt_phone: "altPhone",
  country: "country",
  university: "university",
  permanent_city: "permanentCity",
  loan_amount: "loanAmount",
  verified_email: "verifiedEmail",
  verified_phone: "verifiedPhone",
  created_at: "createdAt",
  updated_at: "updatedAt",
  sanction_agent: "sanctionAgent",
  am_agent: "amAgent",
  rg_agent: "rgAgent",
  lost_reason: "lostReason",
  status_list: "statusList",
  lender_data: "lenderData",
  is_repeated: "isRepeated",
  lqt_agent: "lqtAgent",
  is_renewed: "isRenewed",
  is_lqt_qualified: "isLqtQualified",
  application_id: "applicationId",
  important_tags: "importantTags",
};

export const NOTE_MAPPER = {
  created_at: "createdAt",
  created_by: "createdBy",
  created_by_system: "createdBySystem",
  sender_type: "senderType",
  sender_image: "senderImage",
};

export const LEAD_ASSIGNEE_MAPPER = {
  loan_partner: "loanPartner",
};

export const MARKETING_DETAILS_MAPPER = {
  utm_source: "utmSource",
  utm_campaign: "utmCampaign",
  utm_medium: "utmMedium",
  utm_term: "utmTerm",
  utm_content: "utmContent",
  client_ip: "clientIP",
};

export const BASIC_DETAILS_MAPPER = {
  first_name: "firstName",
  last_name: "lastName",
  phone: "MobileNo",
  alt_phone: "secondaryMobileNo",
  country_code: "countryCode",
  alt_country_code: "alternateCountryCode",
};

export const ACADEMIC_DETAILS_MAPPER = {
  tenth_marks: "tenthMarks",
  twelfth_marks: "twelfthMarks",
  bachelor_marks: "bachelorMarks",
  masters_marks: "mastersMarks",
  academic_gap: "academicGap",
  work_gap: "workGap",
  no_of_backlogs: "noOfBacklogs",
};

export const ADMISSION_DETAILS_MAPPER = {
  course: "areaOfStudy",
  admission_status: "admissionStatus",
  overall_score: "overallScore",
  university_join_date: "intake",
};

export const LOAN_DETAILS_MAPPER = {
  loan_amount: "loanAmount",
  loan_type: "loanType",
  existing_emi: "existingEMI",
  employment_type: "employmentType",
  already_applied_to: "alreadyAppliedTo",
  annual_income: "annualIncome",
  work_experience: "workExperience",
  current_salary: "currentSalary",
};

export const CO_APPLICANT_MAPPER = {
  first_name: "firstName",
  last_name: "lastName",
  country_code: "countryCode",
  existing_emi: "ongoingEmis",
  employment_type: "employmentType",
};

export const TASK_MAPPER = {
  lead_link: "leadLink",
  lead_status: "leadStatus",
  application_status: "applicationStatus",
  task_category: "taskCategory",
  remind_at: "remindAt",
  created_by: "createdBy",
  created_at: "createdAt",
  updated_at: "updatedAt",
  is_complete: "isCompleted",
  created_by_system: "createdBySystem",
};

export const LOAN_APPLICATION_MAPPER = {
  uc_status: "ucStatus",
  sanction_details: "sanctionDetails",
  application_id: "applicationId",
  loan_amount: "loanAmount",
  sanctioned_at: "sanctionedAt",
  pf_amount: "pfAmount",
  pf_details: "pfDetails",
  pf_comment: "pfComment",
  paid_at: "paidAt",
  pf_amount_paid: "pfAmountPaid",
  first_tranche_amount: "firstTrancheAmount",
  no_of_tranches: "noOfTranches",
  tranches_comments: "pfComments",
  cancellation_details: "cancellationDetails",
  created_at: "createdAt",
  lender_details: "lenderDetails",
  is_diy: "isDiy",
  lender_name: "lenderName",
  partner_name: "partnerName",
  disbursal_details: "disbursalDetails",
  disbursed_amount: "disbursedAmount",
  disbursal_date: "disbursalDate",
  disbursed_comment: "disbursedComment",
  cancelled_by: "cancelledBy",
  proof_of_pf_confirmation: "proofOfPfConfirmation",
  proof_of_disbursal: "proofOfDisbursal",
  file_type: "fileType",
  review_id: "reviewId",
  disbursal_id: "disbursalId",
};

export const DOCUMENTS_MAPPER = {
  coapplicant_count: "coapplicantCount",
  mandatory_documents: "mandatoryDocuments",
  total_documents: "totalDocuments",
  meta_data: "metaData",
  document_type: "documentType",
  created_at: "createdAt",
  created_by: "createdBy",
};

export const COPY_LEAD_DETAILS_MAPPER = {
  name: "Name",
  alt_phone: "Alt Phone",
  permanent_city: "City",
  loan_amount: "Loan Amount",
  country: "Country",
  phone: "Mobile",
  university: "University",
  email: "Email",
};

export const COPY_SANCTION_MAPPER = {
  applicationId: "Application ID",
  sanctionedAt: "Sanctioned date",
  loanAmount: "Loan Amount",
  roi: "Rate of Interest",
  tenure: "Tenure",
  pfAmount: "Processing Fee",
};

export const COPY_PFSTATUS_MAPPER = {
  status: "PF Paid",
  paidAt: "Payment Date",
  firstTrancheAmount: "First Tranche Amount",
  noOfTranches: "Number of Tranches",
  pfComment: "Comments",
  pfAmountPaid: "PF Amount Paid",
};

export const COPY_BASIC_DETAILS_MAPPER = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneWithCode: "Secondary Mobile Number",
  pincode: "Pincode",
  state: "State",
  city: "City",
};

export const COPY_ADMISSION_DETAILS_MAPPER = {
  admissionStatus: "Admission Status",
  areaOfStudy: "Course",
  country: "Country",
  degree: "Degree",
  university: "University",
  intake: "Intake",
  exams: "Exams",
  overallScore: "Overall Score",
};

export const COPY_ACADEMIC_DETAILS_MAPPER = {
  tenthMarks: "10th Marks",
  twelfthMarks: "12th Marks",
  bachelorMarks: "Bachelor Marks",
  mastersMarks: "Masters Marks",
  noOfBacklogs: "Number Of Backlogs",
  workGap: "Work Gap",
  academicGap: "Academic Gap",
};

export const COPY_LOAN_DETAILS_MAPPER = {
  loanAmount: "Loan Amount",
  loanType: "Loan Type",
  employmentType: "Employment Type",
  existingEMI: "Existing EMI",
  alreadyAppliedTo: "Already Applied To",
  annualIncome: "Annual Income",
  workExperience: "Work Experience",
  company: "Company",
};

export const COPY_COAPPLICANT_MAPPER = {
  Name: "Name",
  relation: "Relation",
  phone: "Phone Number",
  email: "Email",
  dob: "Date of Birth",
  ongoingEmis: "Ongoing EMIs",
  employed: "Employed",
  pincode: "Pincode",
  state: "State",
  city: "City",
  income: "Income",
  profession: "Profession",
};

export const TEMPLATES_MAPPER = {
  whatsapp_enabled: "whatsappEnabled",
  email_enabled: "emailEnabled",
  body_whatsapp: "bodyWhatsapp",
  body_email: "bodyEmail",
  subject_email: "subjectEmail",
};
