//* Packages Imports */
import { useEffect, useRef, useState } from "react";

//* Components Imports */
import LeadCard from "@Components/pages/BulkAssignment/LeadCard";
import Button from "@Core/Button";
import Checkbox from "@Core/Checkbox";
import Pagination from "@Core/Pagination";
import Select from "@Core/Select";
import SkeletonList from "@Core/SkeletonLoader/List";
import { STATUS_COLOR } from "@Core/StatusBadge/constants";
import QueryFilters from "@Components/Filter/QueryFilters";
import Modal from "@Components/utility/Modal";
import ReassignLeads from "@Components/pages/BulkAssignment/ReassignModal/ReassignLeads";
import BulkActionCSVUploader from "@Components/pages/BulkAssignment/ReassignModal/BulkActionCSVUploader";

//* Actions Imports */
import {
  getLostReasons,
  getTags,
  getTeamMembers,
  getUtmMediums,
  getUtmSources,
} from "@Actions/app";
import {
  bulkLeadReassignmentApi,
  getAllLeads,
  getAllStatus,
} from "@Actions/dashboard";
import { presignedDocLink } from "@Actions/lead";

//* Hooks Imports */
import { useAppDispatch, useAppSelector } from "@Hooks/redux-hooks";

//* Constansts Imports */
import { PAGE_SIZE_OPTIONS } from "@Constants/common";
import { LEADS_FILTER_CONFIG, LEADS_FILTER_KEYS } from "@Constants/filtering";

//* Types & Enums Imports */
import {
  PRESIGNED_DOCUMENT_KINDS,
  StatusKeyEnum,
  USER_TYPE,
} from "@Enums/common";
import { StatusKey } from "@Types/common";
import { BULK_ACTION_TYPE } from "@Enums/dashboard";
import { IAgentInfo } from "@Types/dashboard";
import { LeadTeamType } from "@Types/leads";

export default function BulkAssignment() {
  const dispatch = useAppDispatch();
  const csvDocPathRef = useRef<{ fileName: string; key: string }[] | undefined>(
    undefined,
  );
  const { tags, team, lostReasons, utmSources, utmMediums } = useAppSelector(
    (state) => state.app,
  );
  const leads = useAppSelector((state) => state.dashboard.leads);
  const { role } = useAppSelector((state) => state.user);

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [pageLimit, setPageLimit] = useState({ label: "20" });
  const [activeTab, setActiveTab] = useState<StatusKey | null>(null);
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [reassignLeadsModal, setReassignLeadsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentReassignLeadsModal, setCurrentReassignLeadsModal] = useState<
    string | null
  >(null);

  const statusList = leads.statusList;
  const isUserLender = role === USER_TYPE.LENDER;

  const fetchNewData = (val: any) => {
    setPage(1);
    setFilters(val);
  };

  const updatedStatusList = statusList?.filter((status) => {
    if (role === USER_TYPE.LQT_HEAD) {
      return status === StatusKeyEnum.PRE_QUALIFIED;
    } else if (role === USER_TYPE.AM_AGENT) {
      return [
        StatusKeyEnum.CREATED,
        StatusKeyEnum.OPPORTUNITY,
        StatusKeyEnum.CONTACTED,
      ].includes(status as StatusKeyEnum);
    } else if (role === USER_TYPE.SALES_HEAD) {
      return [
        StatusKeyEnum.CREATED,
        StatusKeyEnum.OPPORTUNITY,
        StatusKeyEnum.CONTACTED,
        StatusKeyEnum.PRE_QUALIFIED,
      ].includes(status as StatusKeyEnum);
    }
    return true;
  });

  const renderLeadStatus = () => {
    const baseButtonClasses =
      "flex items-center justify-center w-40 h-10 rounded-lg text-xs flex-none px-3 border";

    if (!updatedStatusList?.length) return null;

    return updatedStatusList.map((status) => {
      const isActive = activeTab === status;
      const statusColor = STATUS_COLOR[status] || {};
      const activeButtonClasses = isActive
        ? `font-semibold ${statusColor.color || ""} ${statusColor.text || "text-white"}`
        : "text-black";

      return (
        <button
          key={status}
          className={`${baseButtonClasses} ${statusColor.border} ${activeButtonClasses}`}
          onClick={() => {
            setActiveTab(status);
            setPage(1);
          }}
        >
          {statusColor.label}
          {leads.statusCount?.[status] !== undefined && (
            <span
              className={`font-medium ml-auto opacity-70 ${isActive ? "font-semibold" : ""}`}
            >
              {leads.statusCount[status]}
            </span>
          )}
        </button>
      );
    });
  };

  const handleSelectAll = (checked: boolean): void => {
    if (!leads.list) return;
    const currentPageIds: string[] = leads.list.map((lead: { id: number }) =>
      String(lead.id),
    );
    if (checked) {
      setSelectedLeads((prevSelected: string[]) =>
        Array.from(new Set([...prevSelected, ...currentPageIds])),
      );
    } else {
      setSelectedLeads((prevSelected: string[]) =>
        prevSelected.filter((id: string) => !currentPageIds.includes(id)),
      );
    }
  };

  const handleLeadsCheckBox = (val: string | number, checked: boolean) => {
    setSelectedLeads((prevSelectedLeads: any[]) =>
      checked
        ? [...prevSelectedLeads, val]
        : prevSelectedLeads.filter((lead) => lead !== val),
    );
  };

  const handleCancel = () => {
    setSelectedLeads([]);
    setReassignLeadsModal(false);
    setIsLoading(false);
  };

  const handleModalState = (renderSteps: string) => {
    setCurrentReassignLeadsModal(renderSteps);
    setReassignLeadsModal(true);
  };

  const handleNextSteps = async (csvFile: FileList) => {
    setIsLoading(true);
    if (csvFile?.length) {
      csvDocPathRef.current = await presignedDocLink(
        csvFile,
        PRESIGNED_DOCUMENT_KINDS.BULK_ACTION,
      );

      if (csvDocPathRef.current?.length)
        setCurrentReassignLeadsModal("selectAgent");
    }
    setIsLoading(false);
  };

  const bulkLeadsReassign = async (agentConfig: IAgentInfo) => {
    setIsLoading(true);
    try {
      const leadsReassignData = {
        s3FilePath: csvDocPathRef.current
          ? csvDocPathRef.current[0]?.key
          : undefined,
        leadsIds: selectedLeads.length > 0 ? selectedLeads : undefined,
        agentType: agentConfig.type as LeadTeamType,
        agentId: agentConfig.info.id,
      };
      await bulkLeadReassignmentApi(leadsReassignData);
      handleCancel();
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab) {
      dispatch(
        getAllLeads({
          page_size: pageLimit.label,
          page,
          status: activeTab,
          ...filters,
        }),
      );
    }
  }, [page, pageLimit, activeTab, filters]);

  useEffect(() => {
    if (statusList?.length && !activeTab) {
      setActiveTab(statusList[0]);
    }
  }, [statusList]);

  useEffect(() => {
    if (!statusList.length) dispatch(getAllStatus());

    if (!isUserLender) {
      if (!tags.length) dispatch(getTags());
      if (!lostReasons.length) dispatch(getLostReasons());
      if (!utmSources.length) dispatch(getUtmSources());
      if (!utmMediums.length) dispatch(getUtmMediums());
      if (!team?.length) dispatch(getTeamMembers());
    }
  }, []);

  const reassignLeadsModalSteps: Record<string, JSX.Element> = {
    csvUploader: (
      <BulkActionCSVUploader
        actionType={BULK_ACTION_TYPE.BULK_REASSIGN}
        handleNextSteps={handleNextSteps}
        isFileUploading={isLoading}
      />
    ),
    selectAgent: (
      <ReassignLeads
        selectedLeads={selectedLeads}
        handleCancel={handleCancel}
        bulkLeadsReassign={bulkLeadsReassign}
        isLoading={isLoading}
      />
    ),
  };

  return (
    <div className="flex flex-col">
      <div className="bg-white min-h-12 border-y border-r border-gray-border p-4 ">
        <QueryFilters
          filterConfig={LEADS_FILTER_CONFIG}
          filterKeys={LEADS_FILTER_KEYS}
          preAppliedFilters={filters}
          handleApplyFilters={fetchNewData}
          hasClearAll
        />
      </div>

      {/* Lead Status Filter */}
      <div className="flex flex-row overflow-x-auto gap-x-3 no-scrollbar -mr-5 px-5 h-12 mt-4">
        {renderLeadStatus()}
      </div>

      {/* REASSIGN LEADS CTA */}
      <div className="flex mt-4 ml-4 justify-between">
        <Button
          variant="outline"
          classes="min-w-[160px] max-w-[200px]"
          font="sm"
          disabled={!selectedLeads.length}
          onClick={() => handleModalState("selectAgent")}
        >
          {selectedLeads.length
            ? `Reassign ${selectedLeads.length} Leads`
            : "Reassign Leads"}
        </Button>

        {/* UPLOAD CSV CTA */}
        <div className="flex justify-evenly w-1/3 items-center">
          <Button
            variant="solid"
            classes="w-[160px] flex justify-center !bg-black"
            font="sm"
            disabled={selectedLeads.length}
            onClick={() => handleModalState("csvUploader")}
          >
            Reassign With CSV
          </Button>

          <div className="h-4 w-[1px] bg-gray-border" />
          <div className="text-xs text-tag-gray font-medium flex items-center gap-x-2">
            Showing
            <div className="text-gray">
              <Select
                options={PAGE_SIZE_OPTIONS}
                valueKey="label"
                placeholder="10"
                defaults={pageLimit}
                size="xs"
                setValue={(data) => {
                  setPageLimit(data);
                  setPage(1);
                  setSelectedLeads([]);
                }}
                disabled={leads.loading || !leads.list?.length}
              />
            </div>
            rows
          </div>
        </div>
      </div>

      <div className="ml-4">
        <div className="grid grid-cols-5 items-center text-xs text-dark-gray p-4 mt-2">
          <div className="flex items-center gap-3">
            <div>
              <Checkbox
                name="select-all"
                label=""
                value="select-all"
                checked={
                  leads?.list?.length > 0 &&
                  leads.list.every((lead: any) =>
                    selectedLeads.includes(String(lead.id)),
                  )
                }
                onChange={(_, checked) => handleSelectAll(checked)}
                disabled={leads.loading || !leads.list?.length}
              />
            </div>

            <span>Lead ID</span>
          </div>
          <span>Lead Status</span>
          <span>Lead Name</span>
          <span>Login Agent</span>
          <span>Assigned on</span>
        </div>

        {leads.loading ? (
          <SkeletonList gridCols={4} listLength={Number(pageLimit.label)} />
        ) : leads.list?.length > 0 ? (
          leads.list?.map((lead) => (
            <LeadCard
              key={lead.id}
              data={lead}
              onClick={handleLeadsCheckBox}
              isCheckedState={selectedLeads.includes(String(lead.id))}
            />
          ))
        ) : (
          <div>No data found</div>
        )}

        {!leads.loading && Number(leads?.pages) > 1 && (
          <div>
            <Pagination
              totalPages={leads.pages || 1}
              currentPage={page}
              setCurrentPage={(num: number) => {
                setPage(num);
              }}
            />
          </div>
        )}
      </div>
      {reassignLeadsModal && currentReassignLeadsModal !== null && (
        <Modal
          open={reassignLeadsModal}
          onClose={() => setReassignLeadsModal(false)}
        >
          {reassignLeadsModalSteps[currentReassignLeadsModal]}
        </Modal>
      )}
    </div>
  );
}
