//* Packages Imports
import { Link, useLocation } from "react-router-dom";

//* Assets Import */
import BlueTag from "@Assets/icons/tagBlue.png";
import StatusChange from "@Assets/icons/statusChange.png";
import StatusChangeBlue from "@Assets/icons/status-change-blue.png";
import GrayTag from "@Assets/icons/tagGray.png";

const SideBar = () => {
  const location = useLocation();

  const SIDEBAR_MENU_ITEMS = [
    {
      title: "Bulk Assignment",
      nonActiveIcon: GrayTag,
      activeIcon: BlueTag,
      url: "/dashboard/bulk-assignment",
      isAllowed: true, // In Future, this will be replaced with the actual RBAC Permission
    },
    {
      title: "Bulk Status Change",
      nonActiveIcon: StatusChange,
      activeIcon: StatusChangeBlue,
      url: "/dashboard/bulk-status-change",
      isAllowed: true, // In Future, this will be replaced with the actual RBAC Permission
    },
  ];

  // Filter the menu items based on the access
  const updatedSideBarMenuItems = SIDEBAR_MENU_ITEMS.filter(
    (item) => item.isAllowed,
  );

  return (
    <div className="flex mt-4 items-start flex-col justify-around px-4 gap-1">
      {updatedSideBarMenuItems.map((item) => (
        <Link
          key={item.title}
          to={item.url}
          className={`flex items-center gap-3 text-sm font-medium px-4 text-gray w-[216px] h-[48px] ${location.pathname === item.url && "bg-background-gray rounded-lg text-link"}`}
        >
          <img
            src={
              location.pathname === item.url
                ? item.activeIcon
                : item.nonActiveIcon
            }
            alt={item.title}
          />
          <span>{item.title}</span>
        </Link>
      ))}
    </div>
  );
};

export default SideBar;
