import { useState } from "react";
import Radio from "@Core/Radio";

const RadioWrapper = ({
  label,
  options,
  name,
  defaultCheckedItem,
  disabled,
  onChange = () => {},
  mandatory,
  radioOptionLayout = "row",
}: any) => {
  const [checkedItem, setCheckedItem] = useState(defaultCheckedItem);

  return (
    <div className="flex flex-col items-start gap-1">
      {label && (
        <label className="min-w-32 text-xs font-medium text-charcoal-gray m-0">
          {mandatory && <span className="text-error mr-[2px]">*</span>}
          {label}
        </label>
      )}
      <div
        className={`flex ${
          radioOptionLayout === "column" ? "flex-col gap-3" : "flex-row gap-12"
        } whitespace-nowrap text-charcoal-gray m-0`}
      >
        {options?.map((option: any) => (
          <Radio
            key={option.value}
            id={`${name}-${option.value}`}
            label={option.label}
            value={option.value}
            checked={checkedItem === option.value}
            disabled={disabled || false}
            handleChange={(val) => {
              setCheckedItem(val);
              onChange(val);
            }}
            optionJSX={option.optionJSX}
            showOptionJSX={option.showOptionJSX}
            font={option.font || "xs"}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioWrapper;
