//* Packages */
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//* Slices */
import {
  setAllLeadsLoading,
  setAllLeads,
  setStatusList,
  setStatusCountData,
  setStatusLoading,
  setMyTasksLoading,
  setMyTasks,
} from "@Slice/DashboardSlice";

//* Utils */
import API from "@Axios/main";
import {
  BULK_REASSIGN,
  BULK_STATUS_CHANGE,
  GET_MY_TASKS,
  LEAD_DETAILS,
  LEAD_STATUS,
  LEAD_STATUS_COUNT,
} from "@Constants/urls";
import { toastService } from "@Utils/toast";
import { ALL_LEADS_MAPPER, MY_TASKS_MAPPER } from "@Mappers/dashboard";
import { transformLeadCreatedBy } from "@Utils/lead";

//* Types & Enums */
import {
  IBulkReassignPayload,
  IBulkStatusChangePayload,
  ILeadsListingItem,
} from "@Types/dashboard";
import {
  ILeadTasks,
  IPageCountPaginatedResponse,
  StatusKey,
} from "@Types/common";

type IFiltersPayload = Record<string, string | number>;

const _API = new API();

export const getAllLeads = createAsyncThunk(
  "dashboard/getAllLeads",
  async (filters: IFiltersPayload, { dispatch }) => {
    dispatch(setAllLeadsLoading(true));
    try {
      const response: IPageCountPaginatedResponse<ILeadsListingItem[]> =
        await _API.get(LEAD_DETAILS, ALL_LEADS_MAPPER, {
          params: filters || {},
        });

      dispatch(
        setAllLeads({
          pages: response.totalPages,
          count: response.count,
          list: response.results || [],
        }),
      );
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching leads!",
      );
    } finally {
      dispatch(setAllLeadsLoading(false));
    }
  },
);

interface IStatusResponse {
  data: StatusKey[];
}

export const getAllStatus = createAsyncThunk(
  "dashboard/getAllStatus",
  async (_, { dispatch }) => {
    try {
      const response: IStatusResponse = await _API.get(LEAD_STATUS);
      if (Array.isArray(response.data)) {
        dispatch(setStatusList(response.data));
      }
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching status!",
      );
    }
  },
);

export const getStatusCount = createAsyncThunk(
  "dashboard/getStatusCount",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(setStatusLoading(true));
      const response: IStatusResponse = await _API.get(
        LEAD_STATUS_COUNT,
        {},
        {
          params: {
            ...payload,
          },
        },
      );
      if (response) {
        dispatch(setStatusCountData(response));
      }
    } catch (err: unknown) {
      console.error("Error: ", err);
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching count!",
      );
    } finally {
      dispatch(setStatusLoading(false));
    }
  },
);

export const getMyTasks = createAsyncThunk(
  "dashboard/getMyTasks",
  async (filters: IFiltersPayload, { dispatch }) => {
    dispatch(setMyTasksLoading(true));
    try {
      const response: IPageCountPaginatedResponse<ILeadTasks[]> =
        await _API.get(GET_MY_TASKS, MY_TASKS_MAPPER, {
          params: filters || {},
        });

      const allTasks = transformLeadCreatedBy(response.results);
      dispatch(
        setMyTasks({
          pages: response.totalPages,
          count: response.count,
          list: allTasks,
        }),
      );
    } catch (err: unknown) {
      let errorMessage;
      if (err instanceof AxiosError) {
        errorMessage = err.response?.data?.message || err.message;
      }
      console.error("Error: ", errorMessage);
      toastService.notify(
        "error",
        errorMessage || "An error occured while fetching tasks!",
      );
    } finally {
      dispatch(setMyTasksLoading(false));
    }
  },
);

export const bulkLeadReassignmentApi = async (data: IBulkReassignPayload) => {
  try {
    const payload = {
      s3_file_path: data.s3FilePath,
      lead_ids: data.leadsIds,
      agent_type: data.agentType,
      agent_id: data.agentId,
    };
    const response = await _API.post(BULK_REASSIGN, payload);

    if (response) {
      toastService.notify(
        "success",
        "The lead reassignment process has started. This may take a few moments",
      );
    }
  } catch (error) {
    let errorMessage;
    if (error instanceof AxiosError) {
      errorMessage = error.response?.data?.message || error.message;
    }
    console.error("Error: ", errorMessage);
    toastService.notify(
      "error",
      errorMessage || "An error occurred while reassigning. Please try again!",
    );
  }
};

export const bulkStatusChangeApi = async (data: IBulkStatusChangePayload) => {
  try {
    const payload = {
      s3_file_path: data.s3FilePath,
      current_status: data.currentStatus,
      updated_status: data.updatedStatus,
      lost_reason: data.lostReason,
    };
    const response = await _API.post(BULK_STATUS_CHANGE, payload);

    if (response) {
      toastService.notify(
        "success",
        "The lead status-change process has started. This may take a few moments",
      );
    }
  } catch (error) {
    let errorMessage;
    if (error instanceof AxiosError) {
      errorMessage = error.response?.data?.message || error.message;
    }
    console.error("Error: ", errorMessage);
    toastService.notify(
      "error",
      errorMessage || "An error occurred while reassigning. Please try again!",
    );
  }
};
