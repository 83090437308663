//* Packages Imports */
import { useState } from "react";

//* Components Imports */
import Modal from "@Components/utility/Modal";
import { ForUniAccoModal } from "@Components/pages/leads/lead-details/CrossSale/ForUniAccoModal";
import { ForUniScholarsModal } from "@Components/pages/leads/lead-details/CrossSale/ForUniScholarsModal";

//* Utils Imports */
import { useAppSelector } from "@Hooks/redux-hooks";

export default function CrossSale() {
  const [showUniAccoModal, setShowUniAccoModal] = useState(false);
  const [showUniScholarsModal, setShowUniScholarsModal] = useState(false);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);

  const leadData = useAppSelector((state) => state.lead.leadData);

  const isForwardedToUniScholars = leadData?.tags?.some(
    (tag) => tag.code === "forwarded-to-unischolars",
  );

  const handleAccoModal = () => {
    setShowUniAccoModal(!showUniAccoModal);
  };

  const handleScholarsModal = () => {
    setShowUniScholarsModal(!showUniScholarsModal);
  };

  const handleForwardToUniAcco = () => {
    handleAccoModal();
  };

  const handleForwardToUniScholars = () => {
    if (isForwardedToUniScholars) return;
    handleScholarsModal();
  };

  return (
    <div className="flex gap-4 relative">
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray">Forward to</span>
        <div className="relative">
          <button
            onClick={handleForwardToUniAcco}
            onMouseEnter={() => setHoveredButton("uniacco")}
            onMouseLeave={() => setHoveredButton(null)}
            className="px-2 py-1 bg-[#F05D4E] text-sm text-white rounded-md hover:opacity-90 transition-colors font-semibold"
          >
            UniAcco
          </button>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="relative">
          <button
            onClick={handleForwardToUniScholars}
            onMouseEnter={() => setHoveredButton("unischolars")}
            onMouseLeave={() => setHoveredButton(null)}
            className="px-2 py-1 bg-[#27A88A] text-sm font-semibold text-white rounded-md hover:opacity-90 transition-colors"
          >
            UniScholars
          </button>
          {hoveredButton === "unischolars" && isForwardedToUniScholars && (
            <div className="absolute bg-black text-white text-xs p-2 rounded-md left-1/2 -translate-x-1/2 top-10">
              Lead already forwarded to UniScholars
            </div>
          )}
        </div>
      </div>

      {showUniAccoModal && (
        <Modal open={showUniAccoModal} onClose={handleAccoModal}>
          <ForUniAccoModal onClose={handleAccoModal} />
        </Modal>
      )}

      {showUniScholarsModal && (
        <Modal open={showUniScholarsModal} onClose={handleScholarsModal}>
          <ForUniScholarsModal onClose={handleScholarsModal} />
        </Modal>
      )}
    </div>
  );
}
