//* Package Import
import { ChangeEventHandler, useState } from "react";

//* Component Imports //
import Input from "@Core/Input";

//* Assets Imports */
import Upload from "@Assets/icons/upload.png";
import File from "@Assets/icons/file-icon.png";
import Error from "@Assets/icons/error.png";

export type InputFieldsType = {
  name: string;
  label?: string;
  buttonText?: string;
  helperText?: string;
  errorEnabled?: boolean;
  className?: string;
  mandatory?: boolean;
  disabled?: boolean;
  accept?: string;
  multiple?: boolean;
  labelPosition?: "left" | "top";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Restrict to <input> only
  defaultValue?: string[];
  required?: boolean;
};

const FileInput = (props: InputFieldsType) => {
  const [fileNames, setFileNames] = useState<string[] | null>(null);
  const {
    name,
    label,
    helperText,
    buttonText,
    errorEnabled,
    mandatory,
    className,
    disabled = false,
    accept = "*",
    multiple = false,
    labelPosition,
    onChange,
    defaultValue,
    required = false,
  } = props;

  const handleFileChange: ChangeEventHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = e.target.files;
    if (files) {
      const names = Array.from(files).map((file) => file.name);
      setFileNames(names);
    }
    onChange?.(e);
  };

  return (
    <div>
      <Input
        id={name}
        labelPosition={labelPosition}
        className={className}
        label={label}
        type="file"
        name={name}
        onChange={handleFileChange}
        disabled={disabled}
        helperText={helperText}
        mandatory={mandatory}
        accept={accept}
        multiple={multiple}
        required={required}
      />

      <label
        htmlFor={name}
        className={`flex items-center justify-center px-4 py-1 mt-2 border rounded-lg text-sm cursor-pointer${disabled ? "border-gray-border text-tag-gray hover:cursor-not-allowed" : "border-royal-blue text-royal-blue hover:bg-blue-50"}`}
      >
        <img src={Upload} className="w-3 h-3 mr-2" alt="Upload Icon" />
        {buttonText || "Upload File"}
      </label>
      {errorEnabled && (
        <div className="flex flex-row items-center gap-1 mt-2">
          <img src={Error} alt="errorIocn" />
          <span className="text-xs text-error ">
            Error uploading, try again.
          </span>
        </div>
      )}

      <div className="mt-4">
        {(fileNames?.length ? fileNames : defaultValue || []).map(
          (item, index) => (
            <div
              key={`${item}-${index}`}
              className="flex flex-row items-center mt-4"
            >
              <img src={File} alt="file-icon" className="w-5 h-5" />
              {fileNames?.length ? (
                <p className="text-sm underline">{item}</p>
              ) : (
                <a
                  href={item}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm underline"
                >
                  view file
                </a>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default FileInput;
